import moment from 'moment'
import {
  IPaginationResponse,
  IPostMonthlyVolumes,
  IProformaInvoicesFilter,
  IProformaInvoiceDetails,
  TableSort,
  IPostProformaInvoicesGenerate,
  IProformaInvoice
} from 'src/shared/interfaces'
import { DATE_FORMAT_DB } from 'src/shared/constants'

import Http from './api'
import { generatePaginationPayload } from '../lib/paginationPayload'

export const getAllProformaInvoices = (
  filter: IProformaInvoicesFilter,
  sort: TableSort<IProformaInvoice> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      expected_delivery_period_start: [
        filter.expected_delivery_period_start
          ? moment(filter.expected_delivery_period_start).format(DATE_FORMAT_DB)
          : undefined,
        filter.expected_delivery_period_end
          ? moment(filter.expected_delivery_period_end).format(DATE_FORMAT_DB)
          : undefined
      ]
    },
    searchBy: ['customer', 'eic'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IProformaInvoice>>('/proforma-invoices', payload)
}

export const getProformaInvoiceDetail = (id: number) => {
  return Http.get<IProformaInvoiceDetails>(`/proforma-invoices/${id}`)
}

export const getProformaInvoicePdf = (id: number) => {
  return Http.get<unknown>(`/proforma-invoices/pdf/${id}`)
}

export const postProformaInvoicesGenerate = (payload: IPostProformaInvoicesGenerate) => {
  return Http.post<never>(`/proforma-invoices/generate`, payload)
}

export const postMonthlyVolumes = (payload: IPostMonthlyVolumes) => {
  return Http.post<never>(`/monthly-volumes/create-multiple`, payload)
}

export const exportProformaInvoices = (filter: IProformaInvoicesFilter) => {
  const payload = generatePaginationPayload({
    filter: {
      expected_delivery_period_start: [
        filter.expected_delivery_period_start
          ? moment(filter.expected_delivery_period_start).format(DATE_FORMAT_DB)
          : undefined,
        filter.expected_delivery_period_end
          ? moment(filter.expected_delivery_period_end).format(DATE_FORMAT_DB)
          : undefined
      ]
    },
    searchBy: ['customer', 'eic'],
    search: filter.search,
    sort: undefined,
    page: 1,
    size: 10
  })
  return Http.getFile<unknown>(`/proforma-invoices/xls/export/?filter=${JSON.stringify(payload)}`)
}

export const removeProformaInvoiceById = (id: number) => {
  return Http.delete(`/proforma-invoices/${id}`)
}
