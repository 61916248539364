import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, ColorsType, Container, Modal, Typography } from '@otion-core/sandy'

import { arraysEqual } from 'src/lib/arraysEqual'
import { CommodityTypeEnum, IContractDetails, IInvoiceGroup } from 'src/shared/interfaces'
import { useNotifications } from 'src/shared/hooks'
import { processContract } from 'src/redux/actions'

interface Props {
  contractDetails: IContractDetails
  contractInvoiceGroups: IInvoiceGroup[]
  onClose: () => void
  callback: () => void
  color?: ColorsType
}

const ProcessContractModal = ({ contractDetails, contractInvoiceGroups, onClose, callback, color }: Props) => {
  const dispatch = useDispatch()
  const { showError, showSuccess } = useNotifications()
  const [loading, setLoading] = useState(false)
  const { t: tCommon } = useTranslation()

  const handleClose = () => {
    onClose()
  }

  const onProcessContract = async () => {
    setLoading(true)
    const response = await dispatch(processContract(contractDetails.id))
    setLoading(false)
    if (response?.status >= 200 && response?.status < 400) {
      showSuccess('Kontrakt bol úspešne spracovaný')
      callback()
    } else {
      showError(typeof response?.message === 'string' ? response.message : 'Kontrakt sa nepodarilo spracovať')
    }
  }

  const isInvalidDpConfiguration = !arraysEqual(
    contractInvoiceGroups?.flatMap(group => group.delivery_points?.map(eicOrPod => eicOrPod)),
    contractDetails.delivery_points
      ?.map(eicOrPod => {
        if (contractDetails.commodity_type === CommodityTypeEnum.ELECTRICITY && 'eic_id' in eicOrPod) {
          return eicOrPod.eic_id
        }
        if (contractDetails.commodity_type === CommodityTypeEnum.GAS && 'pod_id' in eicOrPod) {
          return eicOrPod.pod_id
        }
        return undefined
      })
      .filter(eicOrPod => eicOrPod !== undefined)
  )

  return (
    <Modal
      title={`Spracovať ${contractDetails.contract_type === '1' ? 'kontrakt' : 'dodatok'}`}
      open
      closeHandler={handleClose}
    >
      <Container>
        Po spracovaní nebudete môcť tento {contractDetails.contract_type === '1' ? 'kontrakt' : 'dodatok'} upravovať ani
        odstraňovať.
      </Container>

      {isInvalidDpConfiguration ? (
        <Container top='large' bottom='large'>
          <Typography color='red' weight='semibold'>
            OM kontraktu sa nezhodujú s OM fakturačných skupín
          </Typography>
        </Container>
      ) : null}

      <Container fullWidth top={1}>
        <Container flex>
          <Button variant='outlined' color='green' onClick={handleClose} style={{ marginRight: 20 }}>
            {tCommon('CANCEL')}
          </Button>
          <Button
            variant='primary'
            color={`${color}.light`}
            onClick={onProcessContract}
            loading={loading}
            disabled={isInvalidDpConfiguration || loading}
          >
            <Typography size='medium' weight='semibold' color={color}>
              Spracovať
            </Typography>
          </Button>
        </Container>
      </Container>
    </Modal>
  )
}

ProcessContractModal.displayName = 'ProcessContractModal'
export default ProcessContractModal
