import styled from 'styled-components'
import { Container } from '@otion-core/sandy'

const Wrapper = styled(Container)`
  animation: fade-in 150ms ease-in-out forwards;
  animation-delay: 300ms;
  opacity: 0;
  transform: translateX(0);

  &::after {
    content: '';
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 5px solid ${props => props.theme.colors.black.default};
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
  }

  &.placement-right {
    &::after {
      rotate: 90deg;
      left: 0;
      transform: translateX(-20px);
    }
  }

  &.placement-left {
    &::after {
      rotate: -90deg;
      left: auto;
      right: 0;
      transform: translateX(20px);
    }
  }

  &.placement-top-end {
    min-width: 85px;
    transform: translateX(23px);

    &::after {
      left: 100%;
      transform: translateX(-60px);
    }
  }

  &.placement-bottom,
  &.placement-bottom-start,
  &.placement-bottom-end {
    &::after {
      content: none;
    }

    &::before {
      content: '';
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 5px solid ${props => props.theme.colors.black.default};
      height: 0;
      left: 50%;
      position: absolute;
      transform: translateX(-50%) translateY(-100%);
      width: 0;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`
export { Wrapper }
