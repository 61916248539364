import React from 'react'
import { useTranslation } from 'react-i18next'

import { InvoicingCurveEnum, PaymentMethodEnum } from 'src/shared/interfaces'
import { DetailPageInputField } from 'src/components'
import { InfoList } from 'src/components/commonStyled'

interface InvoiceSettingsDetailsProps {
  values: any
  errors: any
  isEditMode: boolean
  isGas: boolean
}

const InvoiceSettingsDetails = ({ values, errors, isEditMode, isGas }: InvoiceSettingsDetailsProps) => {
  const { t: tCommon } = useTranslation()
  const { t } = useTranslation('electricity')

  const invoicingCurveOptions = Object.values(InvoicingCurveEnum).map(item => ({
    value: item,
    text: tCommon(`INVOICING_CURVE.${item}` as never)
  }))

  return (
    <InfoList>
      <DetailPageInputField
        column={1}
        name='e_invoicing'
        label={t('portfolio.contracts.otherInfo.e_invoicing')}
        values={values}
        errors={errors}
        isEditMode={isEditMode}
        selectProps={{
          options: [
            { value: 'true', text: tCommon('YES') },
            { value: 'false', text: tCommon('NO') }
          ]
        }}
      />
      <DetailPageInputField
        column={1}
        name='email_for_invoicing'
        label={t('portfolio.contracts.otherInfo.email_for_invoicing')}
        values={values}
        errors={errors}
        isEditMode={isEditMode}
      />
      <DetailPageInputField
        column={1}
        name='amount_of_proforma_invoice_percent'
        label={t('portfolio.contracts.otherInfo.amount_of_proforma_invoice_percent')}
        values={values}
        errors={errors}
        isEditMode={isEditMode}
      />
      {isGas ? (
        <DetailPageInputField
          column={1}
          name='invoicing_curve'
          label={t('portfolio.contracts.otherInfo.invoicing_curve')}
          selectProps={{ searchable: true, options: invoicingCurveOptions }}
          isEditMode={isEditMode}
          values={values}
        />
      ) : null}
      <DetailPageInputField
        column={1}
        name='payment_method'
        label={t('portfolio.contracts.otherInfo.payment_method')}
        values={values}
        errors={errors}
        isEditMode={isEditMode}
        selectProps={{
          options: [
            {
              value: PaymentMethodEnum.PAYMENT,
              text: t(`portfolio.contracts.paymentMethods.${PaymentMethodEnum.PAYMENT}`)
            },
            {
              value: PaymentMethodEnum.INKASO,
              text: t(`portfolio.contracts.paymentMethods.${PaymentMethodEnum.INKASO}`)
            }
          ]
        }}
      />
      <DetailPageInputField
        column={2}
        name='due_day_of_proforma_invoices'
        label={t('portfolio.contracts.otherInfo.due_day_of_proforma_invoices')}
        values={values}
        errors={errors}
        isEditMode={isEditMode}
      />
      <DetailPageInputField
        column={2}
        name='due_day_of_reconciliation_invoices'
        label={t('portfolio.contracts.otherInfo.due_day_of_reconciliation_invoices')}
        values={values}
        errors={errors}
        isEditMode={isEditMode}
      />
      <DetailPageInputField
        column={2}
        name='iban'
        label={t('portfolio.contracts.otherInfo.iban')}
        values={values}
        errors={errors}
        isEditMode={isEditMode}
      />
      <DetailPageInputField
        column={2}
        name='swift'
        label={t('portfolio.contracts.otherInfo.swift')}
        values={values}
        errors={errors}
        isEditMode={isEditMode}
      />
    </InfoList>
  )
}

InvoiceSettingsDetails.displayName = 'InvoiceSettingsDetails'

export default InvoiceSettingsDetails
