import styled from 'styled-components'
import { Button, Typography, Container, Toolbox, ColorsType, getBPHelpers, Link } from '@otion-core/sandy'

const TabButton = styled(Button)<{ selected?: boolean }>`
  background: ${({ selected }) => {
    const selectedColor = '#d9d8d5'
    const defaultColor = '#f0f0f0'
    return selected ? selectedColor : defaultColor
  }} !important;
  border-radius: 0 !important;
  box-shadow: none;
  color: #222 !important;
  font-size: 14px !important;
  height: 38px !important;
  min-width: 100px !important;

  &:first-child {
    border-radius: 20px 0 0 20px !important;
  }

  &:last-child {
    border-radius: 0 20px 20px 0 !important;
  }
`

const ColorDot = styled.span<{ color?: string; size?: number }>`
  background-color: ${({ color, theme }) => color || theme.colors['light-grey'].default};
  border-radius: 50%;
  display: inline-flex;
  margin-right: 7px;
  height: ${({ size }) => size || 8}px;
  width: ${({ size }) => size || 8}px;
`

const Error = styled(Typography)`
  font-size: 12px !important;
  margin: 10px 0 10px 20px;
  color: ${({ theme }) => theme.colors.red.default} !important;
`

const ConnectionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 20px;
  padding-bottom: 20px;
`

const CardBox = styled(Toolbox)<{ disabled?: boolean; color: ColorsType }>`
  width: 180px !important;
  height: 110px !important;
  margin-right: 10px;
  margin-bottom: 10px;

  > div {
    border-radius: 20px !important;
    border: 1px solid ${({ disabled, theme, color }) => (disabled ? '#DEE0E1' : theme.colors[color].default)};
    background-color: ${({ disabled, theme }) => (disabled ? '#DEE0E1' : theme.colors.white.default)} !important;
  }

  svg {
    stroke: ${({ disabled, theme, color }) => (disabled ? '#ffffff' : theme.colors[color].default)} !important;
  }

  p {
    color: ${({ disabled, theme, color }) => (disabled ? '#ffffff' : theme.colors[color].default)} !important;
  }
`

const FilterContainer = styled.div`
  min-width: 230px;
  width: 230px;
  margin-right: 30px;

  ${props => getBPHelpers(props.theme.breakpoints).screens('small')} {
    display: none;
  }
`

const FilterLeftContainer = styled(Container)`
  width: 225px;
  min-height: 350px;
  border-right: 1px solid #c5c7c8;
  padding-right: ${({ theme }) => theme.spacing.medium}rem;
`

const FilterRightContainer = styled.div`
  flex: 1;
  padding: 0 24px;
`

const FilterOptions = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  margin-top: 15px;
`

const InfoList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row dense;
  margin-top: 30px;

  > div {
    display: flex;
    align-items: center;
    min-height: 50px;
    padding-right: 50px;

    p {
      margin: 0;
    }
  }

  ${props => getBPHelpers(props.theme.breakpoints).screens('small')} {
    display: block;

    > div {
      padding-right: 10px;

      > * {
        width: 50%;
        max-width: unset;
      }
    }
  }
`

const IconButton = styled(Button)`
  border-radius: 50% !important;
  box-shadow: 0 2px 2px rgb(0 0 0 / 15%);
  height: 38px !important;
  margin: 2px 10px 2px 0 !important;
  min-width: 38px !important;
  padding: 0 !important;
  width: 38px !important;

  svg {
    fill: ${({ color, theme }) => {
      const splitColor = color?.split('.')
      if (splitColor?.[1] === 'light') return theme.colors.black.default
      return theme.colors.white.default
    }};
    stroke: none;
  }

  span svg {
    stroke: none;
  }

  ${({ loading, theme }) =>
    loading
      ? `
  background-color: ${theme.colors['light-grey'].default};
 
  .is-loading {
    width: 30px;
    height: 38px;
    margin: 0;
  }
  
  svg {
    display: none;
  }
  `
      : ''}
`

const Table = styled.table`
  border-spacing: 0;
  font-size: 14px;
  font-weight: 500;
  width: calc(100% + 16px);
  margin: 0 -8px;

  &.weight-normal {
    font-weight: 400;
  }

  > tbody,
  > thead {
    > tr {
      > th,
      > td {
        padding: 6px 8px;
        text-align: left;

        &.align-right {
          text-align: right;
        }
      }

      > th {
        font-weight: 600;
      }
    }
  }

  > tbody > tr {
    &:nth-child(2n) {
      background-color: #f5f6f8;
    }

    &.bold {
      td {
        font-weight: 700;
      }
    }
  }
`

const LinkWithIcon = styled(Link)<{ iconSize?: number; color: 'green' | 'blue' | 'orange' | 'black' }>`
  color: ${({ color }) => {
    switch (color) {
      case 'green':
        return '#6aad23'
      case 'blue':
        return '#21aaaa'
      case 'orange':
        return '#e67e22'
      case 'black':
        return '#222'
      default:
        return '#222'
    }
  }};
  padding-left: 5px;

  svg {
    width: ${({ iconSize }) => iconSize || 11}px;
    height: ${({ iconSize }) => iconSize || 11}px;
    fill: currentcolor;
  }
`

export {
  TabButton,
  ColorDot,
  Error,
  IconButton,
  InfoList,
  ConnectionsContainer,
  CardBox,
  Table,
  FilterContainer,
  FilterLeftContainer,
  FilterRightContainer,
  FilterOptions,
  LinkWithIcon
}
