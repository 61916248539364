import * as PaymentInvoicesGasApi from '../../apis/paymentInvoicesGas.api'
import { IPaymentInvoiceGas, IPaymentInvoicesGasFilter, PromiseAction, TableSort } from '../../shared/interfaces'

// Get paginated payment invoices
export const GET_ALL_PAYMENT_INVOICES_GAS_TRIGGERED = 'GET_ALL_PAYMENT_INVOICES_GAS_TRIGGERED'
export const GET_ALL_PAYMENT_INVOICES_GAS_SUCCESS = 'GET_ALL_PAYMENT_INVOICES_GAS_SUCCESS'
export const GET_ALL_PAYMENT_INVOICES_GAS_FAILURE = 'GET_ALL_PAYMENT_INVOICES_GAS_FAILURE'

export function getAllPaymentInvoicesGas(
  filter: IPaymentInvoicesGasFilter,
  sort: TableSort<IPaymentInvoiceGas> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = PaymentInvoicesGasApi.getAllPaymentInvoicesGas(filter, sort, page, size)
  return {
    onRequest: GET_ALL_PAYMENT_INVOICES_GAS_TRIGGERED,
    onSuccess: GET_ALL_PAYMENT_INVOICES_GAS_SUCCESS,
    onFailure: GET_ALL_PAYMENT_INVOICES_GAS_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

// Get payment invoice details
export const GET_PAYMENT_INVOICE_GAS_DETAIL_TRIGGERED = 'GET_PAYMENT_INVOICE_GAS_DETAIL_TRIGGERED'
export const GET_PAYMENT_INVOICE_GAS_DETAIL_SUCCESS = 'GET_PAYMENT_INVOICE_GAS_DETAIL_SUCCESS'
export const GET_PAYMENT_INVOICE_GAS_DETAIL_FAILURE = 'GET_PAYMENT_INVOICE_GAS_DETAIL_FAILURE'

export function getPaymentInvoiceGasDetail(invoiceId: number): PromiseAction {
  const promise = PaymentInvoicesGasApi.getPaymentInvoiceGasDetail(invoiceId)
  return {
    onRequest: GET_PAYMENT_INVOICE_GAS_DETAIL_TRIGGERED,
    onSuccess: GET_PAYMENT_INVOICE_GAS_DETAIL_SUCCESS,
    onFailure: GET_PAYMENT_INVOICE_GAS_DETAIL_FAILURE,
    promise,
    payload: { invoiceId }
  }
}

// Export invoices list in xls
export const EXPORT_PAYMENT_INVOICES_GAS_TRIGGERED = 'EXPORT_PAYMENT_INVOICES_GAS_TRIGGERED'
export const EXPORT_PAYMENT_INVOICES_GAS_SUCCESS = 'EXPORT_PAYMENT_INVOICES_GAS_SUCCESS'
export const EXPORT_PAYMENT_INVOICES_GAS_FAILURE = 'EXPORT_PAYMENT_INVOICES_GAS_FAILURE'

export function exportPaymentInvoicesGas(filter: IPaymentInvoicesGasFilter): PromiseAction {
  const promise = PaymentInvoicesGasApi.exportPaymentInvoicesGas(filter)
  return {
    onRequest: EXPORT_PAYMENT_INVOICES_GAS_TRIGGERED,
    onSuccess: EXPORT_PAYMENT_INVOICES_GAS_SUCCESS,
    onFailure: EXPORT_PAYMENT_INVOICES_GAS_FAILURE,
    promise
  }
}

// Generate Gas Payment Invoices
export const GENERATE_PAYMENT_INVOICES_GAS_TRIGGERED = 'GENERATE_PAYMENT_INVOICES_GAS_TRIGGERED'
export const GENERATE_PAYMENT_INVOICES_GAS_SUCCESS = 'GENERATE_PAYMENT_INVOICES_GAS_SUCCESS'
export const GENERATE_PAYMENT_INVOICES_GAS_FAILURE = 'GENERATE_PAYMENT_INVOICES_GAS_FAILURE'

export function generatePaymentInvoicesGas(): PromiseAction {
  const promise = PaymentInvoicesGasApi.generatePaymentInvoiceGas()
  return {
    onRequest: GENERATE_PAYMENT_INVOICES_GAS_TRIGGERED,
    onSuccess: GENERATE_PAYMENT_INVOICES_GAS_SUCCESS,
    onFailure: GENERATE_PAYMENT_INVOICES_GAS_FAILURE,
    promise
  }
}

// remove gas payment invoice
export const REMOVE_PAYMENT_GAS_INVOICE_TRIGGERED = 'REMOVE_PAYMENT_GAS_INVOICE_TRIGGERED'
export const REMOVE_PAYMENT_GAS_INVOICE_SUCCESS = 'REMOVE_PAYMENT_GAS_INVOICE_SUCCESS'
export const REMOVE_PAYMENT_GAS_INVOICE_FAILURE = 'REMOVE_PAYMENT_GAS_INVOICE_FAILURE'

export function removePaymentGasInvoiceById(id: number): PromiseAction {
  const promise = PaymentInvoicesGasApi.removePaymentGasInvoiceById(id)
  return {
    onRequest: REMOVE_PAYMENT_GAS_INVOICE_TRIGGERED,
    onSuccess: REMOVE_PAYMENT_GAS_INVOICE_SUCCESS,
    onFailure: REMOVE_PAYMENT_GAS_INVOICE_FAILURE,
    promise
  }
}
