import React, { SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form, Formik } from 'formik'
import { Badge, Card, ColorsType, Container, Icon, Input, Typography } from '@otion-core/sandy'

import { IColumn, TableSort } from 'src/shared/interfaces'
import { useQuickFilters } from 'src/shared/hooks'
import { ButtonWhite } from 'src/components'
import { displayFormattedNumber } from 'src/lib/displayPrice'
import * as S from 'src/components/commonStyled'

import FilterPaneModal, { TabConfig } from './FilterPaneModal'

interface QuickFilter<T> {
  filterName: string
  filterValues: T
  label: string
  count?: number
  sort?: TableSort<any>
}

interface FilterMenuProps<FilterValues = unknown> {
  color: ColorsType
  applyFilter: (payload: SetStateAction<FilterValues> & FilterValues) => void
  filter: FilterValues
  filteredCount?: number
  totalCount?: number
  searchPlaceholder?: string
  quickFilters?: QuickFilter<FilterValues>[]
  modalFilterTabs?: TabConfig<FilterValues>[]
  action?: (filter: any) => any
  totals?: Record<string, number>
  columns?: IColumn[]
}

function FilterPane<T>(props: FilterMenuProps<T>) {
  const { t: tCommon } = useTranslation()
  const { filter, applyFilter } = props
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const { selectedFilterOption, onFilterOptionChange } = useQuickFilters({ applyFilter })

  return (
    <Container>
      <Formik
        initialValues={{ search: (filter as { search?: string })?.search ?? '' }}
        onSubmit={values => {
          onFilterOptionChange('search', { search: values?.search?.trim() } as never)
        }}
      >
        {({ resetForm }) => (
          <Form>
            <S.FilterContainer>
              <Container flex style={{ maxWidth: '230px' }} bottom={2} alignItems='center'>
                {props.modalFilterTabs?.length ? (
                  <Container right='small'>
                    {Object.values(filter as object)?.some(Boolean) ? (
                      <S.ColorDot
                        color='red'
                        size={10}
                        style={{ position: 'absolute', zIndex: 1, marginTop: -2, marginLeft: -2 }}
                      />
                    ) : null}
                    <Icon name='filter' color={props.color} onClick={() => setOpenFilterModal(true)} stopPropagation />
                  </Container>
                ) : null}

                <Field
                  name='search'
                  as={Input}
                  icon='search'
                  iconPosition='left'
                  placeholder={props.searchPlaceholder}
                  style={{ height: 40, overflow: 'hidden' }}
                />
              </Container>

              <Typography size='medium' weight='medium'>
                {props.filteredCount}/{props.totalCount}
              </Typography>

              <S.FilterOptions top='medium'>
                <ButtonWhite
                  color={props.color}
                  onClick={() => onFilterOptionChange('all', undefined, resetForm)}
                  active={Object.keys(filter as object).length === 0}
                >
                  {tCommon('ALL_ITEMS')}
                </ButtonWhite>

                {props.quickFilters?.map(quickFilter => (
                  <ButtonWhite
                    key={quickFilter.filterName}
                    color={props.color}
                    onClick={() =>
                      onFilterOptionChange(
                        quickFilter.filterName,
                        quickFilter.filterValues,
                        resetForm,
                        quickFilter.sort
                      )
                    }
                    active={selectedFilterOption === quickFilter.filterName}
                    rightAdornment={
                      quickFilter.count ? (
                        <Badge spacing='xsmall' color={props.color} active>
                          {quickFilter.count}
                        </Badge>
                      ) : undefined
                    }
                    style={{ textAlign: 'left', lineHeight: 1.2 }}
                  >
                    {quickFilter.label}
                  </ButtonWhite>
                ))}
              </S.FilterOptions>

              {props?.totals && props?.columns ? (
                <Card
                  style={{ marginTop: 15, borderRadius: 21, padding: '12px 10px 12px 18px' }}
                  padding={0}
                  heading={tCommon('filterTotals')}
                  open
                  icon={<Icon name='chevronDown' />}
                  iconClose={<Icon name='chevronUp' />}
                  startAdornment={
                    <React.Fragment>
                      {Object.entries(props.totals).map(([key, value]) => {
                        if (value == null) return null
                        const columnConfig = props.columns?.find(column => column.id === key)
                        return (
                          <Container top='xsmall' key={key}>
                            <Typography weight='bold' size='medium'>
                              {columnConfig?.label || key}:
                            </Typography>
                            <Typography size='medium'>
                              {columnConfig?.format?.(value) || displayFormattedNumber(value)}
                            </Typography>
                          </Container>
                        )
                      })}
                    </React.Fragment>
                  }
                />
              ) : null}

              {openFilterModal ? (
                <FilterPaneModal<T>
                  color={props.color}
                  onClose={() => setOpenFilterModal(false)}
                  onApply={filterValues => onFilterOptionChange('custom', filterValues)}
                  filter={filter}
                  modalFilterTabs={props.modalFilterTabs}
                  exportAction={props.action}
                />
              ) : null}
            </S.FilterContainer>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

FilterPane.displayName = 'FilterPane'
export default FilterPane
