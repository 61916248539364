import { ContractPriceEnum } from './contracts.interface'
import { IProformaInvoice } from './proformaInvoices.interface'
import { IPaymentInvoice } from './paymentInvoices.interface'
import { IEic } from './eic.interface'
import { IBill } from './financial.interface'

// eslint-disable-next-line no-shadow
export enum INVOICE_TYPE {
  PROFORMA = 'PROFORMA',
  PAYMENT = 'PAYMENT',
  CORRECTION_PAYMENT = 'CORRECTION PAYMENT'
}

// eslint-disable-next-line no-shadow
export enum INVOICE_STATUS {
  DRAFT = 1,
  ISSUED,
  SENT,
  DELIVERED,
  READ,
  NOT_DELIVERED,
  CANCELED
}

export interface IInvoice {
  company_address_city: string
  company_address_street: string
  company_address_street_no: string
  company_address_zip: string
  company_number: string
  contract_invoice_group_id: number
  contract_number: string
  correspondence_address_city: string
  correspondence_address_street: string
  correspondence_address_street_no: string
  correspondence_address_zip: string
  customer_id?: number
  created_at: string
  customer: string
  delivery: string
  delivery_date: string
  delivery_period_end: string
  delivery_period_start: string
  distribution: number
  due_date: string
  emails: string
  excise_duty: number
  id: number
  invoice_name: string
  invoice_number: string
  issue_date: string
  other_fees: number
  payment_date: string
  payment_invoices: number[]
  price_type: ContractPriceEnum
  status: INVOICE_STATUS
  tax_base_0_percent_vat: number
  tax_base_10_percent_vat: number
  tax_base_20_percent_vat: number
  tax_id: string
  taxable_difference_exclude_vat_eur: number
  taxable_difference_include_vat_eur: number
  taxable_difference_vat_eur: number
  total_deduction: number
  total_exclude_vat_eur: number
  total_include_vat_eur: number
  type_of_invoice: INVOICE_TYPE
  variable_symbol: string
  vat_eur: number
  vat_id: string
  eics?: number[]
  zfa_invoices?: number[]
  zfa_details?: IProformaInvoice[]
  vfa_details?: IPaymentInvoice[]
  eic_details?: IEic[]
  bills?: IBill[]
}

export interface IPostGenerateInvoices {
  date: string
}

export interface IInvoicesFilter {
  id?: number
  search?: string
  type_of_invoice?: INVOICE_TYPE
  status?: INVOICE_STATUS
  delivery_period_start_from?: string | Date
  delivery_period_start_to?: string | Date
}

export interface IPairedBankAccountResponse {
  pairedSuccess: number
  notPaired: number
}
