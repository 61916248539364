import {
  IPaginationResponse,
  IPaymentInvoice,
  IPaymentInvoicesFilter,
  IPaymentInvoiceDetails,
  TableSort,
  IPaymentInvoiceCreatePayload
} from 'src/shared/interfaces'
import moment from 'moment'
import { DATE_FORMAT_DB } from 'src/shared/constants'

import Http from './api'
import { generatePaginationPayload } from '../lib/paginationPayload'

export const getAllPaymentInvoices = (
  filter: IPaymentInvoicesFilter,
  sort: TableSort<IPaymentInvoice> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      'payment_invoice.status': filter.status,
      type_of_invoice: filter.type_of_invoice,
      date_to: [
        filter.date_from ? moment(filter.date_from).format(DATE_FORMAT_DB) : undefined,
        filter.date_to ? moment(filter.date_to).format(DATE_FORMAT_DB) : undefined
      ],
      created_at: [
        filter.created_at_from ? moment(filter.created_at_from).format(DATE_FORMAT_DB) : undefined,
        filter.created_at_to ? moment(filter.created_at_to).format(DATE_FORMAT_DB) : undefined
      ]
    },
    searchBy: ['customer', 'eic'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IPaymentInvoice>>('/payment-invoices', payload)
}

export const getPaymentInvoiceDetail = (id: number) => {
  return Http.get<IPaymentInvoiceDetails>(`/payment-invoices/${id}`)
}

export const createPaymentInvoice = (payload: IPaymentInvoiceCreatePayload) => {
  return Http.post<IPaymentInvoiceDetails>('/payment-invoices/create', payload)
}

export const getConsumptionXls = (eic: string, dateStart: string, dateEnd: string) => {
  return Http.getFile<unknown>(`/consumptions/xls/${eic}?date_start=${dateStart}&date_end=${dateEnd}`)
}
export const exportPaymentInvoices = (filter: IPaymentInvoicesFilter, download = false) => {
  const payload = generatePaginationPayload({
    filter: {
      status: filter.status,
      type_of_invoice: filter.type_of_invoice,
      date_to: [
        filter.date_from ? moment(filter.date_from).format(DATE_FORMAT_DB) : undefined,
        filter.date_to ? moment(filter.date_to).format(DATE_FORMAT_DB) : undefined
      ],
      created_at: [
        filter.created_at_from ? moment(filter.created_at_from).format(DATE_FORMAT_DB) : undefined,
        filter.created_at_to ? moment(filter.created_at_to).format(DATE_FORMAT_DB) : undefined
      ]
    },
    searchBy: ['customer', 'eic'],
    search: filter.search,
    sort: undefined,
    page: 1,
    size: 10
  })
  return Http.getFile<unknown>(
    `/payment-invoices/xls/export?${download ? 'download=true&' : ''}filter=${JSON.stringify(payload)}`
  )
}

export const removePaymentInvoiceById = (id: number) => {
  return Http.delete(`/payment-invoices/${id}`)
}
