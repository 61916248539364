import {
  ILeadDetails,
  ICreateOfferPayload,
  IOffersFilter,
  TableSort,
  IOffer,
  IPaginationResponse
} from 'src/shared/interfaces'

import Http from './api'
import { generatePaginationPayload } from '../lib/paginationPayload'

export const getAllOffers = (filter: IOffersFilter, sort: TableSort<IOffer> | undefined, page: number, size = 10) => {
  const payload = generatePaginationPayload({
    filter,
    searchBy: ['lead.customer'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IOffer>>('/offers', payload)
}

export const getOfferDetail = (id: string) => {
  return Http.get<IOffer>(`offers/${id}`)
}

export const createOfferFromLead = (payload: ICreateOfferPayload) => {
  return Http.post<ILeadDetails>('/leads/offer-from-lead', payload)
}

export const updateOfferDetail = (id: string, payload: IOffer) => {
  return Http.patch<IOffer>(`/offers/${id}`, payload)
}

export const generateDocOffer = (offerId: string) => {
  return Http.getFile<unknown>(`/offers/docx/generate/${offerId}`)
}
