import {
  TableSort,
  PromiseAction,
  ILeadView,
  ILeadsFilter,
  ICreateLeadPayload,
  ILeadDetails
} from '../../shared/interfaces'
import * as LeadsApi from '../../apis/leads.api'

export const GET_LEADS_TRIGGERED = 'GET_LEADS_TRIGGERED'
export const GET_LEADS_SUCCESS = 'GET_LEADS_SUCCESS'
export const GET_LEADS_FAILURE = 'GET_LEADS_FAILURE'

export const getAllLeads = (
  filter: ILeadsFilter,
  sort: TableSort<ILeadView> | undefined,
  page: number,
  size = 10
): PromiseAction => {
  const promise = LeadsApi.getAllLeads(filter, sort, page, size)
  return {
    onRequest: GET_LEADS_TRIGGERED,
    onSuccess: GET_LEADS_SUCCESS,
    onFailure: GET_LEADS_FAILURE,
    promise,
    payload: { filter, page, size }
  }
}

export const GET_LEAD_DETAIL_TRIGGERED = 'GET_LEAD_DETAIL_TRIGGERED'
export const GET_LEAD_DETAIL_SUCCESS = 'GET_LEAD_DETAIL_SUCCESS'
export const GET_LEAD_DETAIL_FAILURE = 'GET_LEAD_DETAIL_FAILURE'

export function getLeadDetail(leadCompanyId: string): PromiseAction {
  const promise = LeadsApi.getLeadDetail(leadCompanyId)
  return {
    onRequest: GET_LEAD_DETAIL_TRIGGERED,
    onSuccess: GET_LEAD_DETAIL_SUCCESS,
    onFailure: GET_LEAD_DETAIL_FAILURE,
    promise,
    payload: { leadCompanyId }
  }
}

export const CREATE_LEAD_TRIGGERED = 'CREATE_LEAD_TRIGGERED'
export const CREATE_LEAD_SUCCESS = 'CREATE_LEAD_SUCCESS'
export const CREATE_LEAD_FAILURE = 'CREATE_LEAD_FAILURE'

export function createLead(values: ICreateLeadPayload): PromiseAction {
  const promise = LeadsApi.createLead(values)
  return {
    onRequest: CREATE_LEAD_TRIGGERED,
    onSuccess: CREATE_LEAD_SUCCESS,
    onFailure: CREATE_LEAD_FAILURE,
    promise,
    payload: { values }
  }
}

export const UPDATE_LEAD_DETAIL_TRIGGERED = 'UPDATE_LEAD_DETAIL_TRIGGERED'
export const UPDATE_LEAD_DETAIL_SUCCESS = 'UPDATE_LEAD_DETAIL_SUCCESS'
export const UPDATE_LEAD_DETAIL_FAILURE = 'UPDATE_LEAD_DETAIL_FAILURE'

export function updateLeadDetail(companyId: string, values: ILeadDetails): PromiseAction {
  const promise = LeadsApi.updateLeadDetail(companyId, values)
  return {
    onRequest: UPDATE_LEAD_DETAIL_TRIGGERED,
    onSuccess: UPDATE_LEAD_DETAIL_SUCCESS,
    onFailure: UPDATE_LEAD_DETAIL_FAILURE,
    promise,
    payload: { companyId }
  }
}
