import * as ClientsApi from '../../apis/clients.api'
import {
  IClientEic,
  IEicFilter,
  IGetSpotPricesPayload,
  IInvoice,
  IInvoicesFilter,
  IConsumption,
  IConsumptionFilter,
  PromiseAction,
  TableSort,
  IGetConsumptionByEicPayload,
  IPodFilter,
  IClientPod,
  CommodityTypeEnum
} from '../../shared/interfaces'

// Get clients EIC
export const GET_CLIENTS_EIC_TRIGGERED = 'GET_CLIENTS_EIC_TRIGGERED'
export const GET_CLIENTS_EIC_SUCCESS = 'GET_CLIENTS_EIC_SUCCESS'
export const GET_CLIENTS_EIC_FAILURE = 'GET_CLIENTS_EIC_FAILURE'

export function getClientsEic(
  filter: IEicFilter,
  sort: TableSort<IClientEic> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = ClientsApi.getClientsEic(filter, sort, page, size)
  return {
    onRequest: GET_CLIENTS_EIC_TRIGGERED,
    onSuccess: GET_CLIENTS_EIC_SUCCESS,
    onFailure: GET_CLIENTS_EIC_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

// Get clients spot prices
export const GET_CLIENTS_SPOT_PRICES_TRIGGERED = 'GET_CLIENTS_SPOT_PRICES_TRIGGERED'
export const GET_CLIENTS_SPOT_PRICES_SUCCESS = 'GET_CLIENTS_SPOT_PRICES_SUCCESS'
export const GET_CLIENTS_SPOT_PRICES_FAILURE = 'GET_CLIENTS_SPOT_PRICES_FAILURE'

export function getClientsSpotPrices(payload: IGetSpotPricesPayload): PromiseAction {
  const promise = ClientsApi.getSpotPrices(payload)
  return {
    onRequest: GET_CLIENTS_SPOT_PRICES_TRIGGERED,
    onSuccess: GET_CLIENTS_SPOT_PRICES_SUCCESS,
    onFailure: GET_CLIENTS_SPOT_PRICES_FAILURE,
    promise
  }
}

// Fetch Clients consumption list
export const GET_CLIENTS_CONSUMPTIONS_TRIGGERED = 'GET_CLIENTS_CONSUMPTIONS_TRIGGERED'
export const GET_CLIENTS_CONSUMPTIONS_SUCCESS = 'GET_CLIENTS_CONSUMPTIONS_SUCCESS'
export const GET_CLIENTS_CONSUMPTIONS_FAILURE = 'GET_CLIENTS_CONSUMPTIONS_FAILURE'

export function getClientsConsumptions(
  filter: IConsumptionFilter,
  sort: TableSort<IConsumption> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = ClientsApi.getClientsConsumption(filter, sort, page, size)
  return {
    onRequest: GET_CLIENTS_CONSUMPTIONS_TRIGGERED,
    onSuccess: GET_CLIENTS_CONSUMPTIONS_SUCCESS,
    onFailure: GET_CLIENTS_CONSUMPTIONS_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

// Get paginated invoices
export const GET_CLIENTS_INVOICES_TRIGGERED = 'GET_CLIENTS_INVOICES_TRIGGERED'
export const GET_CLIENTS_INVOICES_SUCCESS = 'GET_CLIENTS_INVOICES_SUCCESS'
export const GET_CLIENTS_INVOICES_FAILURE = 'GET_CLIENTS_INVOICES_FAILURE'

export function getClientsInvoices(
  filter: IInvoicesFilter,
  sort: TableSort<IInvoice> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = ClientsApi.getClientsInvoices(filter, sort, page, size)
  return {
    onRequest: GET_CLIENTS_INVOICES_TRIGGERED,
    onSuccess: GET_CLIENTS_INVOICES_SUCCESS,
    onFailure: GET_CLIENTS_INVOICES_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

// Download pdf invoice
export const GET_CLIENTS_PDF_INVOICE_TRIGGERED = 'GET_CLIENTS_PDF_INVOICE_TRIGGERED'
export const GET_CLIENTS_PDF_INVOICE_SUCCESS = 'GET_CLIENTS_PDF_INVOICE_SUCCESS'
export const GET_CLIENTS_PDF_INVOICE_FAILURE = 'GET_CLIENTS_PDF_INVOICE_FAILURE'

export function getClientsPdfInvoice(id: number, commodity_type: CommodityTypeEnum): PromiseAction {
  const promise = ClientsApi.getClientPdfInvoice(id, commodity_type)
  return {
    onRequest: GET_CLIENTS_PDF_INVOICE_TRIGGERED,
    onSuccess: GET_CLIENTS_PDF_INVOICE_SUCCESS,
    onFailure: GET_CLIENTS_PDF_INVOICE_FAILURE,
    promise
  }
}

// Fetch Consumption detail by client
export const GET_CONSUMPTION_BY_EIC_BY_CLIENT_TRIGGERED = 'GET_CONSUMPTION_BY_EIC_BY_CLIENT_TRIGGERED'
export const GET_CONSUMPTION_BY_EIC_BY_CLIENT_SUCCESS = 'GET_CONSUMPTION_BY_EIC_BY_CLIENT_SUCCESS'
export const GET_CONSUMPTION_BY_EIC_BY_CLIENT_FAILURE = 'GET_CONSUMPTION_BY_EIC_BY_CLIENT_FAILURE'

export function getConsumptionByEicByClient(eic: string, params: IGetConsumptionByEicPayload): PromiseAction {
  const promise = ClientsApi.getConsumptionByEicByClient(eic, params)
  return {
    onRequest: GET_CONSUMPTION_BY_EIC_BY_CLIENT_TRIGGERED,
    onSuccess: GET_CONSUMPTION_BY_EIC_BY_CLIENT_SUCCESS,
    onFailure: GET_CONSUMPTION_BY_EIC_BY_CLIENT_FAILURE,
    promise
  }
}

// Get eic details by client
export const GET_EIC_DETAIL_BY_CLIENT_TRIGGERED = 'GET_EIC_DETAIL_BY_CLIENT_TRIGGERED'
export const GET_EIC_DETAIL_BY_CLIENT_SUCCESS = 'GET_EIC_DETAIL_BY_CLIENT_SUCCESS'
export const GET_EIC_DETAIL_BY_CLIENT_FAILURE = 'GET_EIC_DETAIL_BY_CLIENT_FAILURE'

let getEicDetailController: AbortController
export function getEicDetailByClient(eicId: number | string): PromiseAction {
  if (getEicDetailController) getEicDetailController.abort()
  getEicDetailController = new AbortController()
  const promise = ClientsApi.getEicDetailByClient(eicId, getEicDetailController.signal)
  return {
    onRequest: GET_EIC_DETAIL_BY_CLIENT_TRIGGERED,
    onSuccess: GET_EIC_DETAIL_BY_CLIENT_SUCCESS,
    onFailure: GET_EIC_DETAIL_BY_CLIENT_FAILURE,
    promise,
    payload: { eicId }
  }
}

// Get clients POD
export const GET_CLIENTS_POD_TRIGGERED = 'GET_CLIENTS_POD_TRIGGERED'
export const GET_CLIENTS_POD_SUCCESS = 'GET_CLIENTS_POD_SUCCESS'
export const GET_CLIENTS_POD_FAILURE = 'GET_CLIENTS_POD_FAILURE'

export function getClientsPod(
  filter: IPodFilter,
  sort: TableSort<IClientPod> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = ClientsApi.getClientsPod(filter, sort, page, size)
  return {
    onRequest: GET_CLIENTS_POD_TRIGGERED,
    onSuccess: GET_CLIENTS_POD_SUCCESS,
    onFailure: GET_CLIENTS_POD_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}
