/* eslint-disable */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Form, Formik, FormikProps } from 'formik'
import { Button, Card, ColorsType, Container, Icon, Typography } from '@otion-core/sandy'
import * as Yup from 'yup'

import {
  CommodityTypeEnum,
  IContractDetails,
  IInvoiceGroup,
  IInvoiceGroupsFormValues,
  InvoiceGroupingType,
  InvoicingCurveEnum,
  IPostInvoiceGroupsPayload,
  PaymentMethodEnum
} from 'src/shared/interfaces'
import { useNotifications } from 'src/shared/hooks'
import { getSamePropertyValue } from 'src/lib/getSamePropertyValue'
import { getContractInvoiceGroups, postContractInvoiceGroups } from 'src/redux/actions'
import { ChevronLeftIcon, EditIcon, SaveIcon } from 'src/assets/icons'
import { IconButton } from 'src/components/commonStyled'
import { Loader } from 'src/components'

import InvoiceSettingsDetails from './InvoiceSettingsDetails'
import InvoiceSettingsCustom from './InvoiceSettingsCustom'

const validationSchema = Yup.object().shape({
  e_invoicing: Yup.string().required(),
  amount_of_proforma_invoice_percent: Yup.string().required(),
  due_day_of_proforma_invoices: Yup.string().required(),
  due_day_of_reconciliation_invoices: Yup.string().required(),
  payment_method: Yup.string().required()
})

interface InvoiceSettingsProps {
  contractDetails: IContractDetails
  contractInvoiceGroups: IInvoiceGroup[]
  canEdit: boolean
  color?: ColorsType
}

const ContractInvoiceSettings = (props: InvoiceSettingsProps) => {
  const { t: tCommon } = useTranslation()
  const { t } = useTranslation('electricity')
  const dispatch = useDispatch()
  const { showError, showSuccess } = useNotifications()
  const [isEditMode, setEditMode] = useState(false)

  const contract = props.contractDetails
  const isGas = contract?.commodity_type === CommodityTypeEnum.GAS
  const invoiceGroups = props.contractInvoiceGroups
  let initialGroupingType = InvoiceGroupingType.CUSTOM
  if (
    !invoiceGroups[0]?.delivery_points?.length ||
    invoiceGroups[0]?.delivery_points?.length === contract?.delivery_points?.length
  ) {
    initialGroupingType = InvoiceGroupingType.TOGETHER
  } else if (
    invoiceGroups?.length === contract?.delivery_points?.length &&
    getSamePropertyValue(invoiceGroups, 'e_invoicing') !== '***' &&
    getSamePropertyValue(invoiceGroups, 'amount_of_proforma_invoice_percent') !== '***' &&
    getSamePropertyValue(invoiceGroups, 'due_day_of_proforma_invoices') !== '***' &&
    getSamePropertyValue(invoiceGroups, 'due_day_of_reconciliation_invoices') !== '***' &&
    getSamePropertyValue(invoiceGroups, 'email_for_invoicing') !== '***' &&
    getSamePropertyValue(invoiceGroups, 'payment_method') !== '***' &&
    getSamePropertyValue(invoiceGroups, 'iban') !== '***' &&
    getSamePropertyValue(invoiceGroups, 'swift') !== '***' &&
    getSamePropertyValue(invoiceGroups, 'invoicing_curve') !== '***'
  ) {
    initialGroupingType = InvoiceGroupingType.SEPARATELY
  }

  const [groupingType, setGroupingType] = useState(initialGroupingType)

  const onSubmitHandler = async (values: IInvoiceGroupsFormValues) => {
    let invoice_groups: IInvoiceGroup[]
    const commonValues = {
      amount_of_proforma_invoice_percent: Number(values.amount_of_proforma_invoice_percent),
      contract_id: contract.id,
      due_day_of_proforma_invoices: Number(values.due_day_of_proforma_invoices),
      due_day_of_reconciliation_invoices: Number(values.due_day_of_reconciliation_invoices),
      e_invoicing: values.e_invoicing !== 'false',
      email_for_invoicing: values.email_for_invoicing,
      payment_method: values.payment_method as PaymentMethodEnum,
      iban: values.iban,
      swift: values.swift,
      invoicing_curve: isGas ? (values.invoicing_curve as InvoicingCurveEnum) : undefined
    }
    if (groupingType === InvoiceGroupingType.TOGETHER) {
      invoice_groups = [
        {
          ...commonValues,
          delivery_points: contract.delivery_points?.map(dp => dp.id) || [],
          group_code: 1
        }
      ]
    } else if (groupingType === InvoiceGroupingType.SEPARATELY) {
      invoice_groups =
        contract.delivery_points?.map((dp, index) => ({
          ...commonValues,
          delivery_points: [dp.id],
          group_code: index + 1
        })) || []
    } else {
      invoice_groups = values.invoice_groups || []
    }
    const payload: IPostInvoiceGroupsPayload = { invoice_groups }
    const response = await dispatch(postContractInvoiceGroups(contract.id, payload))
    await dispatch(getContractInvoiceGroups(contract.id))
    if (response?.status >= 200 && response?.status < 400) {
      showSuccess('Successfully updated')
      setEditMode(false)
    } else {
      showError(tCommon('forms.unsuccessful'))
      console.error(response)
    }
  }

  const email_for_invoicing = getSamePropertyValue(invoiceGroups, 'email_for_invoicing') as string

  const initialValues: IInvoiceGroupsFormValues = {
    e_invoicing: getSamePropertyValue(invoiceGroups, 'e_invoicing') === false ? 'false' : 'true',
    email_for_invoicing: email_for_invoicing ? email_for_invoicing : contract.invoicing_email || '',
    amount_of_proforma_invoice_percent:
      (getSamePropertyValue(invoiceGroups, 'amount_of_proforma_invoice_percent') as string) || '100',
    due_day_of_proforma_invoices:
      (getSamePropertyValue(invoiceGroups, 'due_day_of_proforma_invoices') as string) || '15',
    due_day_of_reconciliation_invoices:
      (getSamePropertyValue(invoiceGroups, 'due_day_of_reconciliation_invoices') as string) || '15',
    payment_method: (getSamePropertyValue(invoiceGroups, 'payment_method') as string) || PaymentMethodEnum.PAYMENT,
    iban: (getSamePropertyValue(invoiceGroups, 'iban') as string) || '',
    swift: (getSamePropertyValue(invoiceGroups, 'swift') as string) || '',
    invoicing_curve: (getSamePropertyValue(invoiceGroups, 'invoicing_curve') as string) || InvoicingCurveEnum.FLAT,
    invoice_groups: invoiceGroups
  }

  // These are used to localize SK string
  const isOneDp = contract.delivery_points?.length === 1
  const isFewDps = Boolean(
    contract.delivery_points && contract.delivery_points.length > 1 && contract.delivery_points.length < 5
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      validationSchema={groupingType !== InvoiceGroupingType.CUSTOM ? validationSchema : undefined}
    >
      {({ errors, setFieldValue, values, isValid, isSubmitting }: FormikProps<IInvoiceGroupsFormValues>) => {
        const hasSelectedAllDps =
          groupingType === InvoiceGroupingType.CUSTOM
            ? values.invoice_groups?.flatMap(group => group.delivery_points)?.length ===
              contract.delivery_points?.length
            : true
        const allGroupsHaveDps =
          groupingType === InvoiceGroupingType.CUSTOM
            ? values.invoice_groups?.every(group => group.delivery_points?.length)
            : true
        const someValuesAreAmbiguous =
          groupingType !== InvoiceGroupingType.CUSTOM
            ? values.e_invoicing === '***' ||
              values.email_for_invoicing === '***' ||
              values.amount_of_proforma_invoice_percent === '***' ||
              values.due_day_of_proforma_invoices === '***' ||
              values.due_day_of_reconciliation_invoices === '***' ||
              values.payment_method === '***' ||
              values.iban === '***' ||
              values.swift === '***' ||
              values.invoicing_curve === '***'
            : false

        const setInitialValues = () => {
          if (initialGroupingType === InvoiceGroupingType.CUSTOM) {
            setFieldValue('e_invoicing', 'true')
            setFieldValue('email_for_invoicing', contract.invoicing_email ? contract.invoicing_email : '')
            setFieldValue('amount_of_proforma_invoice_percent', '100')
            setFieldValue('due_day_of_proforma_invoices', '15')
            setFieldValue('due_day_of_reconciliation_invoices', '15')
            setFieldValue('payment_method', PaymentMethodEnum.PAYMENT)
            setFieldValue('invoice_groups', invoiceGroups)
            setFieldValue('iban', '')
            setFieldValue('swift', '')
            setFieldValue('invoicing_curve', InvoicingCurveEnum.FLAT)
          } else {
            const commonValues = invoiceGroups[0] || {}
            setFieldValue(
              'e_invoicing',
              commonValues.e_invoicing === true ? 'true' : commonValues.e_invoicing === false ? 'false' : 'true'
            )
            setFieldValue(
              'email_for_invoicing',
              commonValues.email_for_invoicing ? commonValues.email_for_invoicing : contract.invoicing_email || ''
            )
            setFieldValue(
              'amount_of_proforma_invoice_percent',
              commonValues.amount_of_proforma_invoice_percent || '100'
            )
            setFieldValue('due_day_of_proforma_invoices', commonValues.due_day_of_proforma_invoices || '15')
            setFieldValue('due_day_of_reconciliation_invoices', commonValues.due_day_of_reconciliation_invoices || '15')
            setFieldValue('payment_method', commonValues.payment_method || PaymentMethodEnum.PAYMENT)
            setFieldValue('iban', commonValues.iban || '')
            setFieldValue('swift', commonValues.swift || '')
            setFieldValue('invoicing_curve', commonValues.invoicing_curve || InvoicingCurveEnum.FLAT)
            setFieldValue('invoice_groups', invoiceGroups)
          }
        }

        return (
          <Form>
            <Card
              heading={
                <Container flex alignItems='center' justifyContent='space-between'>
                  <Container>{t('portfolio.contracts.invoiceSettings')}</Container>
                  <Container flex alignItems='center'>
                    {isEditMode ? (
                      <React.Fragment>
                        <Button
                          variant='primary'
                          size='xsmall'
                          color={`${props.color ?? 'green'}.light`}
                          style={{
                            minWidth: 170,
                            marginRight: 10,
                            height: 38,
                            boxShadow: '0 2px 2px rgb(0 0 0 / 15%)'
                          }}
                          onClick={() => {
                            setEditMode(false)
                            setGroupingType(initialGroupingType)
                            setInitialValues()
                          }}
                        >
                          <Container fullWidth flex>
                            <ChevronLeftIcon height={20} />
                            <Typography align='center' weight='semibold' size='medium'>
                              {tCommon('DROP_CHANGES')}
                            </Typography>
                          </Container>
                        </Button>
                        <IconButton
                          color={`${props.color ?? 'green'}.light`}
                          stopPropagation
                          type='submit'
                          disabled={!isValid || !hasSelectedAllDps || !allGroupsHaveDps || someValuesAreAmbiguous}
                        >
                          <SaveIcon height={20} />
                        </IconButton>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <IconButton color={`${props.color ?? 'green'}.light`} onClick={() => setEditMode(true)}>
                          <EditIcon />
                        </IconButton>
                      </React.Fragment>
                    )}
                  </Container>
                </Container>
              }
            >
              {isSubmitting ? (
                <Container flex justifyContent='center'>
                  <Loader />
                </Container>
              ) : (
                <Container>
                  <Container flex top='small' bottom='medium'>
                    {Object.values(InvoiceGroupingType).map(type => (
                      <Button
                        key={type}
                        color={groupingType === type ? `${props.color + '.light'}` : 'white'}
                        onClick={() => setGroupingType(type)}
                        size='small'
                        style={{ maxWidth: 200, marginRight: 10 }}
                        disabled={groupingType !== type && !isEditMode}
                      >
                        <Typography size='medium' weight='semibold'>
                          {t(`portfolio.contracts.invoiceGroupingTypes.${type}` as never)}
                        </Typography>
                      </Button>
                    ))}
                  </Container>

                  <Container>
                    {groupingType === InvoiceGroupingType.CUSTOM ? (
                      <InvoiceSettingsCustom
                        contractDetails={contract}
                        values={values}
                        errors={errors}
                        isEditMode={isEditMode}
                        setFieldValue={setFieldValue}
                      />
                    ) : (
                      <React.Fragment>
                        <Container flex alignItems='center'>
                          <Container right='xsmall'>
                            <Icon name='bulb' />
                          </Container>

                          {groupingType === InvoiceGroupingType.TOGETHER ? (
                            <Typography size='medium' weight='medium'>
                              Bude sa vystavovať jediná faktúra pro {contract.delivery_points?.length || 0}{' '}
                              {isOneDp ? 'odberné miesto' : isFewDps ? 'odberné miesta' : 'odberných miest'}
                            </Typography>
                          ) : (
                            <Typography size='medium' weight='medium'>
                              {isFewDps ? 'Budú' : 'Bude'} sa vystavovať {contract.delivery_points?.length || 0}{' '}
                              {isOneDp ? 'faktúra' : isFewDps ? 'faktúry' : 'faktúr'} celkom, pro každé odberné miesto
                              zvlášť
                            </Typography>
                          )}
                        </Container>
                        <InvoiceSettingsDetails
                          values={invoiceGroups?.[0] || {}}
                          errors={errors}
                          isEditMode={isEditMode}
                          isGas={isGas}
                        />
                      </React.Fragment>
                    )}
                  </Container>
                </Container>
              )}
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
}

ContractInvoiceSettings.displayName = 'ContractInvoiceSettings'

export default ContractInvoiceSettings
