import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@otion-core/sandy'

import { ColorDot } from 'src/components/commonStyled'
import { ActionStatusEnum } from 'src/shared/interfaces'

interface ActionStatusProps {
  value?: number | string
}

const ActionStatus = (props: ActionStatusProps) => {
  const { t } = useTranslation()
  if (!props.value) return null

  let color

  switch (props.value) {
    case ActionStatusEnum.INCOMPLETE_DATA:
      color = '#e6e6e6'
      break
    case ActionStatusEnum.READY_FOR_SEND:
      color = '#f1e0a7'
      break
    case ActionStatusEnum.SENT:
      color = '#f1e0a7'
      break
    case ActionStatusEnum.DELIVERED:
      color = '#f3d163'
      break
    case ActionStatusEnum.UNPROCESSABLE_REQUEST:
      color = '#ff3b30'
      break
    case ActionStatusEnum.PROCESSED:
      color = '#ffa300'
      break
    case ActionStatusEnum.DECLINED:
      color = '#ff3b30'
      break
    case ActionStatusEnum.CANCEL_DECLINED:
      color = '#ff3b30'
      break
    case ActionStatusEnum.CANCELED:
      color = '#ff3b30'
      break
    case ActionStatusEnum.ACCEPTED:
      color = '#8abd25'
      break
    case ActionStatusEnum.PAIRED:
      color = '#8abd25'
      break
    case ActionStatusEnum.NOT_PAIRED:
      color = '#ff3b30'
      break
  }

  return (
    <Container as='span' style={{ display: 'inline-flex' }} alignItems='center'>
      <ColorDot color={color} />
      <span>{t(`ACTION_STATUS.${ActionStatusEnum[props.value as ActionStatusEnum]}` as never)}</span>
    </Container>
  )
}

ActionStatus.displayName = 'ActionStatus'
export default ActionStatus
