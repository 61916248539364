/* eslint-disable max-lines-per-function,max-lines */
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, FieldArray } from 'formik'
import { ActionBadge, Button, Container, Input, Paper, Select, Typography } from '@otion-core/sandy'

import {
  CommodityTypeEnum,
  IContractDetails,
  IInvoiceGroup,
  IInvoiceGroupsFormValues,
  InvoicingCurveEnum,
  PaymentMethodEnum
} from 'src/shared/interfaces'
import { IconButton, Table } from 'src/components/commonStyled'
import { PlusIcon, TrashIcon } from 'src/assets/icons'
import { copyToClipboard } from 'src/lib/copyToClipboard'

interface InvoiceSettingsCustomProps {
  contractDetails: IContractDetails
  values: IInvoiceGroupsFormValues
  setFieldValue: (name: string, value: any) => void
  errors: any
  isEditMode: boolean
}

const InvoiceSettingsCustom = (props: InvoiceSettingsCustomProps) => {
  const { t: tCommon } = useTranslation()
  const { t } = useTranslation('electricity')

  const assignedDeliveryPoints = useMemo(
    () => props.values.invoice_groups?.flatMap(group => group.delivery_points),
    [props.values.invoice_groups]
  )

  const hasWrongDpCount = assignedDeliveryPoints?.length !== props.contractDetails?.delivery_points?.length

  const unassignedDeliveryPoints = useMemo(() => {
    return (
      props.contractDetails.delivery_points?.filter(
        deliveryPoint => !assignedDeliveryPoints?.includes(deliveryPoint.id)
      ) || []
    )
  }, [props.contractDetails.delivery_points, props.values.invoice_groups])

  const onRemoveDeliveryPoint = (deliveryPointId: number, index: number) => {
    props.setFieldValue(
      `invoice_groups[${index}].delivery_points`,
      props.values.invoice_groups?.[index]?.delivery_points?.filter(item => item !== deliveryPointId)
    )
  }

  const onAddDeliveryPoint = (deliveryPointId: number | undefined, index: number) => {
    if (deliveryPointId) {
      props.setFieldValue(`invoice_groups[${index}].delivery_points`, [
        ...(props.values.invoice_groups?.[index]?.delivery_points || []),
        deliveryPointId
      ])
    }
  }

  const invoicingCurveOptions = Object.values(InvoicingCurveEnum).map(item => ({
    value: item,
    text: tCommon(`INVOICING_CURVE.${item}` as never)
  }))

  const hasMaximumGroups =
    props.values.invoice_groups &&
    props.contractDetails.delivery_points &&
    (props.values.invoice_groups.length >= props.contractDetails.delivery_points.length ||
      unassignedDeliveryPoints.length === 0)

  const isGas = props.contractDetails.commodity_type === CommodityTypeEnum.GAS

  return (
    <Container>
      {hasWrongDpCount ? (
        <Container top='large' bottom='medium' style={{ justifySelf: 'flex-start' }}>
          <Paper background='red'>
            <Container padded='small'>
              <Typography color='white' weight='medium'>
                Wrong number of assigned Delivery Points
              </Typography>
            </Container>
          </Paper>
        </Container>
      ) : null}
      {unassignedDeliveryPoints?.length ? (
        <Container bottom='medium'>
          <Typography size='medium' weight='semibold'>
            {t('portfolio.contracts.otherInfo.unassigned_delivery_points')}:
          </Typography>
          <Container flex style={{ flexWrap: 'wrap' }}>
            {unassignedDeliveryPoints.map(deliveryPoint => (
              <ActionBadge
                key={deliveryPoint.id}
                color='red'
                spacing='xsmall'
                size='small'
                right={null}
                style={{ margin: '5px 5px 0 0' }}
              >
                {props.contractDetails.commodity_type === CommodityTypeEnum.ELECTRICITY && 'eic_id' in deliveryPoint
                  ? deliveryPoint.eic
                  : null}
                {props.contractDetails.commodity_type === CommodityTypeEnum.GAS && 'pod_id' in deliveryPoint
                  ? deliveryPoint.pod
                  : null}
              </ActionBadge>
            ))}
          </Container>
        </Container>
      ) : null}

      <FieldArray name='invoice_groups'>
        {arrayHelpers => (
          <Table>
            <thead>
              <tr>
                <th />
                <th>{t('portfolio.contracts.otherInfo.delivery_points')}</th>
                <th>
                  {t('portfolio.contracts.otherInfo.amount_of_proforma_invoice_percent')}
                  {isGas ? (
                    <React.Fragment> / {t('portfolio.contracts.otherInfo.invoicing_curve')}</React.Fragment>
                  ) : (
                    ''
                  )}
                </th>
                <th>
                  {t('portfolio.contracts.otherInfo.e_invoicing')} /{' '}
                  {t('portfolio.contracts.otherInfo.email_for_invoicing')}
                </th>
                <th>{t('portfolio.contracts.otherInfo.due_day_of_proforma_invoices')}</th>
                <th>{t('portfolio.contracts.otherInfo.due_day_of_reconciliation_invoices')}</th>
                <th>{t('portfolio.contracts.otherInfo.payment_method')}</th>
                <th>
                  {t('portfolio.contracts.otherInfo.iban')} / {t('portfolio.contracts.otherInfo.swift')}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {props.values.invoice_groups?.map((invoiceGroup: IInvoiceGroup, index) => (
                <tr key={index}>
                  <td title={`ID ${invoiceGroup.id}`} onDoubleClick={() => copyToClipboard(invoiceGroup.id)}>
                    {index + 1}
                  </td>
                  <td>
                    {invoiceGroup.delivery_points?.map(deliveryPoint => {
                      const findDeliveryPoint = props.contractDetails.delivery_points?.find(
                        contractDeliveryPoint => contractDeliveryPoint.id === deliveryPoint
                      )
                      return (
                        <ActionBadge
                          key={deliveryPoint}
                          color='green'
                          spacing='xsmall'
                          size='small'
                          onRightClick={
                            props.isEditMode ? () => onRemoveDeliveryPoint(deliveryPoint, index) : undefined
                          }
                          right={props.isEditMode ? undefined : null}
                          style={{ margin: '0 5px 5px 0' }}
                        >
                          {(findDeliveryPoint && 'eic' in findDeliveryPoint && findDeliveryPoint.eic) ||
                            (findDeliveryPoint && 'pod' in findDeliveryPoint && findDeliveryPoint.pod) || (
                              <Typography color='red'>{deliveryPoint}</Typography>
                            )}
                        </ActionBadge>
                      )
                    })}
                    {props.isEditMode && unassignedDeliveryPoints?.length ? (
                      <Select
                        options={unassignedDeliveryPoints
                          .map(deliveryPoint => {
                            let text = ''
                            if ('eic' in deliveryPoint && deliveryPoint.eic) text = deliveryPoint.eic
                            else if ('pod' in deliveryPoint && deliveryPoint.pod) text = deliveryPoint.pod
                            return { value: deliveryPoint.id.toString(), text }
                          })
                          .filter(item => item.text !== '')}
                        onChange={(e, option) => onAddDeliveryPoint(Number(option?.value), index)}
                        inputProps={{ style: { height: 30, width: 150 } }}
                        placeholder={t('portfolio.contracts.otherInfo.add_delivery_point')}
                      />
                    ) : null}
                  </td>
                  <td>
                    {props.isEditMode ? (
                      <React.Fragment>
                        <Field
                          name={`invoice_groups.${index}.amount_of_proforma_invoice_percent`}
                          as={Input}
                          error={Boolean(props.errors?.invoice_groups?.[index]?.amount_of_proforma_invoice_percent)}
                          inputProps={{ style: { height: 30, width: 70 } }}
                        />
                        {isGas ? (
                          <div style={{ marginTop: 4 }}>
                            <Field
                              name={`invoice_groups.${index}.invoicing_curve`}
                              as={Select}
                              options={invoicingCurveOptions}
                              error={Boolean(props.errors?.invoice_groups?.[index]?.invoicing_curve)}
                              inputProps={{ style: { height: 30, width: 80 } }}
                            />
                          </div>
                        ) : null}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div>{invoiceGroup.amount_of_proforma_invoice_percent} %</div>
                        {isGas ? (
                          <div>{tCommon(`INVOICING_CURVE.${invoiceGroup.invoicing_curve}` as never)}</div>
                        ) : null}
                      </React.Fragment>
                    )}
                  </td>
                  <td>
                    <div style={{ marginBottom: 4 }}>
                      {props.isEditMode ? (
                        <Field
                          name={`invoice_groups.${index}.e_invoicing`}
                          as={Select}
                          options={[
                            { value: true, text: tCommon('YES') },
                            { value: false, text: tCommon('NO') }
                          ]}
                          error={Boolean(props.errors?.invoice_groups?.[index]?.e_invoicing)}
                          inputProps={{ style: { height: 30, width: 70 } }}
                        />
                      ) : (
                        <React.Fragment>{invoiceGroup.e_invoicing ? tCommon('YES') : tCommon('NO')}</React.Fragment>
                      )}
                    </div>
                    <div>
                      {!(
                        props.values.invoice_groups?.[index]?.e_invoicing === true ||
                        (props.values.invoice_groups?.[index]?.e_invoicing as unknown as string) === 'true'
                      ) ? null : (
                        <React.Fragment>
                          {props.isEditMode ? (
                            <Field
                              name={`invoice_groups.${index}.email_for_invoicing`}
                              as={Input}
                              error={Boolean(props.errors?.invoice_groups?.[index]?.email_for_invoicing)}
                              inputProps={{ style: { height: 30, width: 140 } }}
                              placeholder={t('portfolio.contracts.otherInfo.email_for_invoicing')}
                            />
                          ) : (
                            invoiceGroup.email_for_invoicing || (
                              <Typography style={{ fontStyle: 'italic', whiteSpace: 'nowrap' }} color='extra-light'>
                                - {tCommon('NOT_PROVIDED')} -
                              </Typography>
                            )
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </td>
                  <td>
                    {props.isEditMode ? (
                      <Field
                        name={`invoice_groups.${index}.due_day_of_proforma_invoices`}
                        as={Input}
                        error={Boolean(props.errors?.invoice_groups?.[index]?.due_day_of_proforma_invoices)}
                        inputProps={{ style: { height: 30, width: 60 } }}
                      />
                    ) : (
                      invoiceGroup.due_day_of_proforma_invoices
                    )}
                  </td>
                  <td>
                    {props.isEditMode ? (
                      <Field
                        name={`invoice_groups.${index}.due_day_of_reconciliation_invoices`}
                        as={Input}
                        error={Boolean(props.errors?.invoice_groups?.[index]?.due_day_of_reconciliation_invoices)}
                        inputProps={{ style: { height: 30, width: 60 } }}
                      />
                    ) : (
                      invoiceGroup.due_day_of_reconciliation_invoices
                    )}
                  </td>
                  <td>
                    {props.isEditMode ? (
                      <Field
                        name={`invoice_groups.${index}.payment_method`}
                        as={Select}
                        options={[
                          {
                            value: PaymentMethodEnum.PAYMENT,
                            text: t(`portfolio.contracts.paymentMethods.${PaymentMethodEnum.PAYMENT}`)
                          },
                          {
                            value: PaymentMethodEnum.INKASO,
                            text: t(`portfolio.contracts.paymentMethods.${PaymentMethodEnum.INKASO}`)
                          }
                        ]}
                        error={Boolean(props.errors?.invoice_groups?.[index]?.payment_method)}
                        inputProps={{ style: { height: 30, width: 95 } }}
                      />
                    ) : (
                      <React.Fragment>
                        {t(`portfolio.contracts.paymentMethods.${invoiceGroup.payment_method}` as never)} <br />
                      </React.Fragment>
                    )}
                  </td>
                  <td>
                    <div style={{ marginBottom: 4 }}>
                      {props.isEditMode ? (
                        <Field
                          name={`invoice_groups.${index}.iban`}
                          as={Input}
                          error={Boolean(props.errors?.invoice_groups?.[index]?.iban)}
                          inputProps={{ style: { height: 30, width: 240 } }}
                          placeholder={t('portfolio.contracts.otherInfo.iban')}
                        />
                      ) : (
                        invoiceGroup.iban
                      )}
                    </div>
                    <div>
                      {props.isEditMode ? (
                        <Field
                          name={`invoice_groups.${index}.swift`}
                          as={Input}
                          error={Boolean(props.errors?.invoice_groups?.[index]?.swift)}
                          inputProps={{ style: { height: 30, width: 110 } }}
                          placeholder={t('portfolio.contracts.otherInfo.swift')}
                        />
                      ) : (
                        invoiceGroup.swift
                      )}
                    </div>
                  </td>
                  <td>
                    {props.isEditMode ? (
                      <IconButton color='grey' onClick={() => arrayHelpers.remove(index)}>
                        <TrashIcon />
                      </IconButton>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
            {props.isEditMode ? (
              <tfoot>
                <tr>
                  <td colSpan={9} style={{ textAlign: 'center' }}>
                    <Button
                      onClick={() =>
                        arrayHelpers.push({
                          delivery_points: [] as number[],
                          e_invoicing: true,
                          email_for_invoicing: props.values.email_for_invoicing || '',
                          amount_of_proforma_invoice_percent: '100',
                          group_code: (props.values.invoice_groups?.length || 0) + 1,
                          due_day_of_proforma_invoices: 15,
                          due_day_of_reconciliation_invoices: 15,
                          payment_method: PaymentMethodEnum.PAYMENT,
                          invoicing_curve: InvoicingCurveEnum.FLAT,
                          contract_id: props.contractDetails.id,
                          iban: '',
                          swift: ''
                        } as any)
                      }
                      fullWidth={false}
                      size='small'
                      variant='outlined'
                      style={{ height: 38 }}
                      icon={<PlusIcon stroke={hasMaximumGroups ? '#c5c7c8' : '#242424'} height={20} />}
                      disabled={hasMaximumGroups}
                    >
                      Add new invoice group
                    </Button>
                  </td>
                </tr>
              </tfoot>
            ) : null}
          </Table>
        )}
      </FieldArray>
    </Container>
  )
}

InvoiceSettingsCustom.displayName = 'InvoiceSettingsCustom'

export default InvoiceSettingsCustom
