import React, { ReactNode, useRef, useState } from 'react'
import { Container, Paper, Typography, Popper as SandyPopper } from '@otion-core/sandy'
import { PopperPlacementType } from '@otion-core/sandy/build/components/Popper/Popper'

import * as S from './styles'

interface PopperProps {
  content: ReactNode
  trigger: ReactNode
  placement?: PopperPlacementType
}

const Tooltip = (props: PopperProps) => {
  const [isPopperOpen, setPopperOpen] = useState(false)
  const popperWrapperRef = useRef<HTMLDivElement>(null)

  if (props.content === null) return <Container ref={popperWrapperRef}>{props.trigger}</Container>

  return (
    <Container ref={popperWrapperRef}>
      <Container
        onMouseEnter={() => setPopperOpen(true)}
        onMouseLeave={() => setPopperOpen(false)}
        style={{ cursor: 'pointer' }}
      >
        {props.trigger}
      </Container>

      <SandyPopper
        open={isPopperOpen}
        anchorEl={popperWrapperRef.current}
        placement={props.placement}
        autoWidth={false}
      >
        <S.Wrapper padded='xsmall' className={`placement-${props.placement}`}>
          <Paper background='black'>
            <Container padded='xsmall' left='xsmall' right='xsmall'>
              <Typography color='white' size='inherit' as='span' style={{ display: 'block', fontSize: 13 }}>
                {props.content}
              </Typography>
            </Container>
          </Paper>
        </S.Wrapper>
      </SandyPopper>
    </Container>
  )
}

Tooltip.defaultProps = {
  placement: 'top'
}

Tooltip.displayName = 'Tooltip'
export default Tooltip
