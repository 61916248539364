import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@otion-core/sandy'

import { ColorDot } from 'src/components/commonStyled'
import { OfferStatusEnum } from 'src/shared/interfaces'

interface OfferStatusProps {
  value?: string
}

const OfferStatus = (props: OfferStatusProps) => {
  const { t } = useTranslation()

  if (!props.value) return null

  let color
  let name

  switch (props.value) {
    case OfferStatusEnum.NEW:
      name = 'NEW'
      color = '#e6e6e6'
      break
    case OfferStatusEnum.CHECKED:
      name = 'CHECKED'
      color = '#f3d163'
      break
    case OfferStatusEnum.ACCEPTED:
      name = 'ACCEPTED'
      color = '#8abd25'
      break
    case OfferStatusEnum.DECLINED:
      name = 'DECLINED'
      color = '#ff3b30'
      break
  }

  return (
    <Container as='span' style={{ display: 'inline-flex' }} alignItems='center'>
      <ColorDot color={color} />
      <span>{t(`CONTRACT_STATUS.${name}` as never)}</span>
    </Container>
  )
}

OfferStatus.displayName = 'OfferStatus'
export default OfferStatus
