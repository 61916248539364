// eslint-disable-next-line no-shadow
import { IOffer } from './offers.interface'

// eslint-disable-next-line no-shadow
export enum ProductTypeEnum {
  SPOT = 'SPOT',
  FIX = 'FIX',
  COMBI = 'COMBI'
}

export interface ICreateLeadPayload {
  customer: string
  company_id: string
  company_address_city: string
  company_address_street: string
  company_address_zip: string
  contact_person: string
  contact_phone: string
  email: string
  next_contact_date: string
  volume_electricity?: string
  volume_gas?: string
  commitment_gas?: string
  commitment_electricity?: string
  product_gas?: ProductTypeEnum
  product_electricity?: ProductTypeEnum
  current_price_gas?: string
  current_price_electricity?: string
}

export interface ILeadView {
  id: number
  customer: string
  company_id: string
  company_address_street: string
  company_address_zip: string
  company_address_city: string
  contact_person: string
  contact_phone: string
  email: string
  next_contact_date: string
  volume_electricity: string
  volume_gas: string
  commitment_gas: string
  commitment_electricity: string
  product_gas: ProductTypeEnum
  product_electricity: ProductTypeEnum
  current_price_gas: string
  current_price_electricity: string
  created_at: string
  created_by: number
  updated_at: string
  updated_by: number
}

export interface ILeadsFilter {
  search?: string
}

export interface ILeadDetails {
  id: number
  customer: string
  company_id: string
  company_address_street: string
  company_address_zip: string
  company_address_city: string
  contact_person: string
  contact_phone: string
  email: string
  next_contact_date: string
  volume_electricity: string
  volume_gas: string
  commitment_gas: string
  commitment_electricity: string
  product_gas: ProductTypeEnum
  product_electricity: ProductTypeEnum
  current_price_gas: string
  current_price_electricity: string
  created_at: string
  created_by: number
  updated_at: string
  updated_by: number
  offers?: IOffer[]
  notes?: string
  trader?: string
}
