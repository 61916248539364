import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ColorsType } from '@otion-core/sandy'

import { IMailbox, MessageFetchType } from '../../shared/interfaces'
import { getMailbox } from '../../redux/actions'
import { useNotifications } from '../../shared/hooks'
import PromptModal from '../PromptModal'

interface FetchMailboxModalProps {
  type: MessageFetchType
  since?: Date
  color: ColorsType
  onClose: () => void
  onSuccess: () => void
}

const FetchMailboxModal = ({ type, since, color, onClose, onSuccess }: FetchMailboxModalProps) => {
  const dispatch = useDispatch()
  const { showSuccess } = useNotifications()
  const { t } = useTranslation()

  const onConfirm = async () => {
    const res = await dispatch(getMailbox({ type, since }))
    if (res?.data) {
      const mailbox = res.data as IMailbox
      showSuccess(
        <React.Fragment>
          Fetched mailbox: {mailbox.mailboxName}
          <br />
          Total messages: {mailbox.total}
          <br />
          Successfully parsed: {mailbox.parsedCount}
        </React.Fragment>,
        15000
      )
      onSuccess?.()
    }
  }

  return (
    <PromptModal
      title={t('fetchMailboxModal.title')}
      description={t('fetchMailboxModal.message', { type })}
      color={color}
      onConfirm={onConfirm}
      closeHandler={onClose}
    />
  )
}

export default FetchMailboxModal
