import { PromiseAction, ICreateOfferPayload, IOffersFilter, TableSort, IOffer } from '../../shared/interfaces'
import * as OffersApi from '../../apis/offers.api'

export const GET_OFFERS_TRIGGERED = 'GET_OFFERS_TRIGGERED'
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS'
export const GET_OFFERS_FAILURE = 'GET_OFFERS_FAILURE'

export const getAllOffers = (
  filter: IOffersFilter,
  sort: TableSort<IOffer> | undefined,
  page: number,
  size = 10
): PromiseAction => {
  const promise = OffersApi.getAllOffers(filter, sort, page, size)
  return {
    onRequest: GET_OFFERS_TRIGGERED,
    onSuccess: GET_OFFERS_SUCCESS,
    onFailure: GET_OFFERS_FAILURE,
    promise,
    payload: { filter, page, size }
  }
}

export const GET_OFFER_DETAIL_TRIGGERED = 'GET_OFFER_DETAIL_TRIGGERED'
export const GET_OFFER_DETAIL_SUCCESS = 'GET_OFFER_DETAIL_SUCCESS'
export const GET_OFFER_DETAIL_FAILURE = 'GET_OFFER_DETAIL_FAILURE'

export function getOfferDetail(id: string): PromiseAction {
  const promise = OffersApi.getOfferDetail(id)
  return {
    onRequest: GET_OFFER_DETAIL_TRIGGERED,
    onSuccess: GET_OFFER_DETAIL_SUCCESS,
    onFailure: GET_OFFER_DETAIL_FAILURE,
    promise,
    payload: { id }
  }
}

export const CREATE_OFFER_TRIGGERED = 'CREATE_OFFER_TRIGGERED'
export const CREATE_OFFER_SUCCESS = 'CREATE_OFFER_SUCCESS'
export const CREATE_OFFER_FAILURE = 'CREATE_OFFER_FAILURE'

export function createOfferFromLead(values: ICreateOfferPayload): PromiseAction {
  const promise = OffersApi.createOfferFromLead(values)
  return {
    onRequest: CREATE_OFFER_TRIGGERED,
    onSuccess: CREATE_OFFER_SUCCESS,
    onFailure: CREATE_OFFER_FAILURE,
    promise,
    payload: { values }
  }
}

export const UPDATE_OFFER_DETAIL_TRIGGERED = 'UPDATE_OFFER_DETAIL_TRIGGERED'
export const UPDATE_OFFER_DETAIL_SUCCESS = 'UPDATE_OFFER_DETAIL_SUCCESS'
export const UPDATE_OFFER_DETAIL_FAILURE = 'UPDATE_OFFER_DETAIL_FAILURE'

export function updateOfferDetail(id: string, values: IOffer): PromiseAction {
  const promise = OffersApi.updateOfferDetail(id, values)
  return {
    onRequest: UPDATE_OFFER_DETAIL_TRIGGERED,
    onSuccess: UPDATE_OFFER_DETAIL_SUCCESS,
    onFailure: UPDATE_OFFER_DETAIL_FAILURE,
    promise,
    payload: { id }
  }
}

export const GENERATE_DOC_OFFER_TRIGGERED = 'GENERATE_DOC_OFFER_TRIGGERED'
export const GENERATE_DOC_OFFER_SUCCESS = 'GENERATE_DOC_OFFER_SUCCESS'
export const GENERATE_DOC_OFFER_FAILURE = 'GENERATE_DOC_OFFER_FAILURE'

export function generateDocOffer(offer_id: string): PromiseAction {
  const promise = OffersApi.generateDocOffer(offer_id)
  return {
    onRequest: GENERATE_DOC_OFFER_TRIGGERED,
    onSuccess: GENERATE_DOC_OFFER_SUCCESS,
    onFailure: GENERATE_DOC_OFFER_FAILURE,
    promise
  }
}
