import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { Button, Card, Container, Icon, Typography } from '@otion-core/sandy'

import { IUpdateUserPayload, IUserDetails, UserRole } from 'src/shared/interfaces'
import { DATE_FORMAT_TIME } from 'src/shared/constants'
import { ChevronLeftIcon, EditIcon, SaveIcon } from 'src/assets/icons'
import { updateMe } from 'src/redux/actions'
import { useNotifications } from 'src/shared/hooks'

import { DetailPageInputField, Loader, Tooltip } from '../../index'
import { IconButton, InfoList } from '../../commonStyled'

interface IUserInfoCard {
  userDetails: IUserDetails
  currentUserRole: UserRole
  setOpenSignInAsModal?: (email: string) => void
}

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required(),
  last_name: Yup.string().optional(),
  phone: Yup.string().optional(),
  email: Yup.string().email().required()
})

const UserInfoCard: FC<IUserInfoCard> = ({ userDetails, currentUserRole, setOpenSignInAsModal }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { showError } = useNotifications()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const handleClinkOnOpenSignIsModal = () => {
    if (setOpenSignInAsModal) {
      setOpenSignInAsModal(userDetails?.email)
    }
  }

  const onSubmitHandler = async (values: IUpdateUserPayload) => {
    setLoading(true)
    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone
    }

    const response = await dispatch(updateMe(payload))
    if (response?.status >= 200 && response?.status < 400) {
      setIsEditMode(false)
    } else {
      showError(t('forms.unsuccessful'))
      console.error(response)
    }
    setIsEditMode(false)
    setLoading(false)
  }

  const initialValues: any = {}
  Object.keys(userDetails).forEach(key => {
    const rawValue = userDetails[key as never] as string | number | null
    const value = typeof rawValue === 'number' ? rawValue.toString() : rawValue
    initialValues[key] = value || ''
  })

  if (loading) {
    return (
      <Container flex justifyContent='center'>
        <Loader />
      </Container>
    )
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues as IUpdateUserPayload}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {({ errors, isValid, resetForm }: FormikProps<IUpdateUserPayload>) => (
          <Form style={{ width: '100%' }}>
            <Container flex justifyContent='space-between' fullWidth top={6}>
              {currentUserRole === UserRole.ADMIN ? (
                <Typography variant='heading' size='medium' weight='semibold'>
                  {userDetails?.role} – {[userDetails?.first_name, userDetails?.last_name].filter(Boolean).join(' ')}
                </Typography>
              ) : (
                <Typography variant='heading' size='medium' weight='semibold'>
                  {t('MY_ACCOUNT')}
                </Typography>
              )}

              <Container flex justifyContent='flex-end'>
                {currentUserRole === UserRole.ADMIN && (
                  <Tooltip
                    trigger={
                      <IconButton color='yellow.light' stopPropagation onClick={handleClinkOnOpenSignIsModal}>
                        <Icon name='logout' size={19} />
                      </IconButton>
                    }
                    content={t('users.signInAs')}
                  />
                )}
                {currentUserRole !== UserRole.ADMIN &&
                  (isEditMode ? (
                    <React.Fragment>
                      <Button
                        color='yellow.light'
                        variant='primary'
                        size='xsmall'
                        style={{
                          minWidth: 170,
                          marginRight: 10,
                          height: 38,
                          boxShadow: '0 2px 2px rgb(0 0 0 / 15%)'
                        }}
                        onClick={() => {
                          setIsEditMode(false)
                          resetForm()
                        }}
                      >
                        <Container fullWidth flex>
                          <ChevronLeftIcon height={20} />
                          <Typography align='center' weight='semibold' size='medium'>
                            {t('DROP_CHANGES')}
                          </Typography>
                        </Container>
                      </Button>
                      <Tooltip
                        trigger={
                          <IconButton color='yellow.light' stopPropagation type='submit' disabled={!isValid}>
                            <SaveIcon width={18} />
                          </IconButton>
                        }
                        content={t('SAVE')}
                      />
                    </React.Fragment>
                  ) : (
                    <Tooltip
                      trigger={
                        <IconButton color='yellow.light' stopPropagation onClick={() => setIsEditMode(true)}>
                          <EditIcon />
                        </IconButton>
                      }
                      content={t('EDIT')}
                    />
                  ))}
              </Container>
            </Container>

            <Container fullWidth top={1}>
              <Card heading={t('USER_INFO')}>
                <InfoList>
                  <DetailPageInputField
                    column={1}
                    name='first_name'
                    label={t('users.keyInfo.first_name')}
                    values={userDetails}
                    isEditMode={isEditMode}
                    errors={errors}
                  />
                  <DetailPageInputField
                    column={1}
                    name='last_name'
                    label={t('users.keyInfo.last_name')}
                    values={userDetails}
                    isEditMode={isEditMode}
                    errors={errors}
                  />
                  <DetailPageInputField
                    column={currentUserRole === UserRole.ADMIN ? 1 : 2}
                    name='email'
                    label={t('users.keyInfo.email')}
                    values={userDetails}
                    isEditMode={isEditMode}
                    errors={errors}
                  />
                  <DetailPageInputField
                    column={currentUserRole === UserRole.ADMIN ? 1 : 2}
                    name='phone'
                    label={t('users.keyInfo.phone')}
                    values={userDetails}
                    isEditMode={isEditMode}
                    errors={errors}
                  />

                  {currentUserRole === UserRole.ADMIN && (
                    <React.Fragment>
                      <DetailPageInputField
                        column={2}
                        name='is_active'
                        label={t('users.keyInfo.is_active')}
                        values={userDetails}
                        format={value => (value ? t('YES') : t('NO'))}
                      />
                      <DetailPageInputField
                        column={2}
                        name='role'
                        label={t('users.keyInfo.role')}
                        values={userDetails}
                      />
                      <DetailPageInputField
                        column={2}
                        name='created_at'
                        label={t('users.keyInfo.created_at')}
                        values={userDetails}
                        format={value => (value ? moment(value).format(DATE_FORMAT_TIME) : undefined)}
                      />
                      <DetailPageInputField
                        column={2}
                        name='last_login_at'
                        label={t('users.keyInfo.last_login_at')}
                        values={userDetails}
                        format={value => (value ? moment(value).format(DATE_FORMAT_TIME) : undefined)}
                      />
                    </React.Fragment>
                  )}
                </InfoList>
              </Card>
            </Container>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  )
}

UserInfoCard.displayName = 'UserInfoCard'

export default UserInfoCard
