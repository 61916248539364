import * as ProformaInvoicesGasApi from '../../apis/proformaInvoicesGas.api'
import {
  IPostProformaInvoicesGasGenerate,
  IProformaInvoiceGas,
  IProformaInvoicesFilter,
  PromiseAction,
  TableSort
} from '../../shared/interfaces'

// Get paginated proforma gas invoices
export const GET_ALL_PROFORMA_INVOICES_GAS_TRIGGERED = 'GET_ALL_PROFORMA_INVOICES_GAS_TRIGGERED'
export const GET_ALL_PROFORMA_INVOICES_GAS_SUCCESS = 'GET_ALL_PROFORMA_INVOICES_GAS_SUCCESS'
export const GET_ALL_PROFORMA_INVOICES_GAS_FAILURE = 'GET_ALL_PROFORMA_INVOICES_GAS_FAILURE'

export function getAllProformaInvoicesGas(
  filter: IProformaInvoicesFilter,
  sort: TableSort<IProformaInvoiceGas> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = ProformaInvoicesGasApi.getAllProformaInvoicesGas(filter, sort, page, size)
  return {
    onRequest: GET_ALL_PROFORMA_INVOICES_GAS_TRIGGERED,
    onSuccess: GET_ALL_PROFORMA_INVOICES_GAS_SUCCESS,
    onFailure: GET_ALL_PROFORMA_INVOICES_GAS_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

// Get gas proforma invoice details
export const GET_PROFORMA_INVOICE_GAS_DETAIL_TRIGGERED = 'GET_PROFORMA_INVOICE_GAS_DETAIL_TRIGGERED'
export const GET_PROFORMA_INVOICE_GAS_DETAIL_SUCCESS = 'GET_PROFORMA_INVOICE_GAS_DETAIL_SUCCESS'
export const GET_PROFORMA_INVOICE_GAS_DETAIL_FAILURE = 'GET_PROFORMA_INVOICE_GAS_DETAIL_FAILURE'

export function getProformaInvoiceGasDetail(invoiceId: number): PromiseAction {
  const promise = ProformaInvoicesGasApi.getProformaInvoiceGasDetail(invoiceId)
  return {
    onRequest: GET_PROFORMA_INVOICE_GAS_DETAIL_TRIGGERED,
    onSuccess: GET_PROFORMA_INVOICE_GAS_DETAIL_SUCCESS,
    onFailure: GET_PROFORMA_INVOICE_GAS_DETAIL_FAILURE,
    promise,
    payload: { invoiceId }
  }
}

// Generate Gas Proforma Invoices for current month

export const POST_PROFORMA_INVOICES_GAS_GENERATE_TRIGGERED = 'POST_PROFORMA_INVOICES_GAS_GENERATE_TRIGGERED'
export const POST_PROFORMA_INVOICES_GAS_GENERATE_SUCCESS = 'POST_PROFORMA_INVOICES_GAS_GENERATE_SUCCESS'
export const POST_PROFORMA_INVOICES_GAS_GENERATE_FAILURE = 'POST_PROFORMA_INVOICES_GAS_GENERATE_FAILURE'

export function postProformaInvoicesGasGenerate(payload: IPostProformaInvoicesGasGenerate): PromiseAction {
  const promise = ProformaInvoicesGasApi.postProformaInvoicesGasGenerate(payload)
  return {
    onRequest: POST_PROFORMA_INVOICES_GAS_GENERATE_TRIGGERED,
    onSuccess: POST_PROFORMA_INVOICES_GAS_GENERATE_SUCCESS,
    onFailure: POST_PROFORMA_INVOICES_GAS_GENERATE_FAILURE,
    promise,
    payload: { payload }
  }
}

// Export proforma invoices list in xls
export const EXPORT_PROFORMA_INVOICES_GAS_TRIGGERED = 'EXPORT_PROFORMA_INVOICES_GAS_TRIGGERED'
export const EXPORT_PROFORMA_INVOICES_GAS_SUCCESS = 'EXPORT_PROFORMA_INVOICES_GAS_SUCCESS'
export const EXPORT_PROFORMA_INVOICES_GAS_FAILURE = 'EXPORT_PROFORMA_INVOICES_GAS_FAILURE'

export function exportProformaInvoicesGas(filter: IProformaInvoicesFilter): PromiseAction {
  const promise = ProformaInvoicesGasApi.exportProformaInvoicesGas(filter)
  return {
    onRequest: EXPORT_PROFORMA_INVOICES_GAS_TRIGGERED,
    onSuccess: EXPORT_PROFORMA_INVOICES_GAS_SUCCESS,
    onFailure: EXPORT_PROFORMA_INVOICES_GAS_FAILURE,
    promise
  }
}

// remove gas proforma invoice
export const REMOVE_PROFORMA_INVOICE_GAS_TRIGGERED = 'REMOVE_PROFORMA_INVOICE_GAS_TRIGGERED'
export const REMOVE_PROFORMA_INVOICE_GAS_SUCCESS = 'REMOVE_PROFORMA_INVOICE_GAS_SUCCESS'
export const REMOVE_PROFORMA_INVOICE_GAS_FAILURE = 'REMOVE_PROFORMA_INVOICE_GAS_FAILURE'

export function removeProformaInvoiceGasById(id: number): PromiseAction {
  const promise = ProformaInvoicesGasApi.removeProformaInvoiceGasById(id)
  return {
    onRequest: REMOVE_PROFORMA_INVOICE_GAS_TRIGGERED,
    onSuccess: REMOVE_PROFORMA_INVOICE_GAS_SUCCESS,
    onFailure: REMOVE_PROFORMA_INVOICE_GAS_FAILURE,
    promise
  }
}
