import {
  TableSort,
  IPaginationResponse,
  ILeadView,
  ILeadsFilter,
  ILeadDetails,
  ICreateLeadPayload
} from 'src/shared/interfaces'
import { generatePaginationPayload } from 'src/lib/paginationPayload'

import Http from './api'

export const getAllLeads = (filter: ILeadsFilter, sort: TableSort<ILeadView> | undefined, page: number, size = 10) => {
  const payload = generatePaginationPayload({
    filter,
    searchBy: ['customer', 'company_id'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<ILeadView>>('/leads', payload)
}

export const getLeadDetail = (leadCompanyId: string) => {
  return Http.get<ILeadDetails>(`/leads/${leadCompanyId}`)
}

export const createLead = (payload: ICreateLeadPayload) => {
  return Http.post<ILeadDetails>('/leads/create', payload)
}

export const updateLeadDetail = (company_id: string, payload: ILeadDetails) => {
  return Http.patch<ILeadDetails>(`/leads/${company_id}`, payload)
}
