import { TableSort } from '../shared/interfaces'

interface PayloadParams {
  page: number
  size: number
  filter?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  searchBy?: string[]
  sumColumns?: string[]
  search?: string
  sort?: TableSort<any> | undefined // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const generatePaginationPayload = ({
  filter,
  sort,
  page,
  size,
  searchBy,
  search,
  sumColumns
}: PayloadParams) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const payload: any = {
    path: '',
    page,
    limit: size
  }
  if (sort) {
    payload.sortBy = [[sort.column, sort.direction]]
  }
  if (filter) {
    payload.filter = {}
    Object.keys(filter).forEach(filterKey => {
      const value = filter[filterKey]
      // Ignore empty values
      if (
        value === undefined ||
        value === null ||
        value === '' ||
        (Array.isArray(value) && !value.some(element => element))
      ) {
        return
      }

      let formattedValue

      // Handle IN conditions (e.g., 'value1,value2,value3')
      if (typeof value === 'object' && Object.hasOwn(value, 'IN')) {
        if (!Array.isArray(value.IN)) return
        formattedValue = `$in:${value.IN.join(',')}`
      } else {
        // Handle negation and range conditions (e.g., '!value', 'value1,value2', 'value1<=', 'value1>=')
        if (value?.startsWith?.('!')) {
          formattedValue = `$not:${value.slice(1)}`
        } else if (Array.isArray(value)) {
          if (Array.isArray(value[0])) formattedValue = value[0]
          else if (value[0] && value[1]) formattedValue = `$btw:${value[0]},${value[1]}`
          else if (value[0]) formattedValue = `$gte:${value[0]}`
          else if (value[1]) formattedValue = `$lte:${value[1]}`
        } else {
          formattedValue = value.toString()
        }
      }

      payload.filter[filterKey] = formattedValue
    })

    if (searchBy && search) {
      payload.searchBy = searchBy
      payload.search = search
    }

    if (sumColumns) {
      payload.sumColumns = sumColumns
    }
  }
  return payload
}
