import * as ProformaInvoicesApi from '../../apis/proformaInvoices.api'
import {
  IPostMonthlyVolumes,
  IPostProformaInvoicesGenerate,
  IProformaInvoice,
  IProformaInvoicesFilter,
  PromiseAction,
  TableSort
} from '../../shared/interfaces'

// Get paginated proforma invoices
export const GET_ALL_PROFORMA_INVOICES_TRIGGERED = 'GET_ALL_PROFORMA_INVOICES_TRIGGERED'
export const GET_ALL_PROFORMA_INVOICES_SUCCESS = 'GET_ALL_PROFORMA_INVOICES_SUCCESS'
export const GET_ALL_PROFORMA_INVOICES_FAILURE = 'GET_ALL_PROFORMA_INVOICES_FAILURE'

export function getAllProformaInvoices(
  filter: IProformaInvoicesFilter,
  sort: TableSort<IProformaInvoice> | undefined,
  page: number,
  size = 10
): PromiseAction {
  const promise = ProformaInvoicesApi.getAllProformaInvoices(filter, sort, page, size)
  return {
    onRequest: GET_ALL_PROFORMA_INVOICES_TRIGGERED,
    onSuccess: GET_ALL_PROFORMA_INVOICES_SUCCESS,
    onFailure: GET_ALL_PROFORMA_INVOICES_FAILURE,
    promise,
    payload: { filter, sort, page, size }
  }
}

// Get proforma invoice details

export const GET_PROFORMA_INVOICE_DETAIL_TRIGGERED = 'GET_PROFORMA_INVOICE_DETAIL_TRIGGERED'
export const GET_PROFORMA_INVOICE_DETAIL_SUCCESS = 'GET_PROFORMA_INVOICE_DETAIL_SUCCESS'
export const GET_PROFORMA_INVOICE_DETAIL_FAILURE = 'GET_PROFORMA_INVOICE_DETAIL_FAILURE'

export function getProformaInvoiceDetail(invoiceId: number): PromiseAction {
  const promise = ProformaInvoicesApi.getProformaInvoiceDetail(invoiceId)
  return {
    onRequest: GET_PROFORMA_INVOICE_DETAIL_TRIGGERED,
    onSuccess: GET_PROFORMA_INVOICE_DETAIL_SUCCESS,
    onFailure: GET_PROFORMA_INVOICE_DETAIL_FAILURE,
    promise,
    payload: { invoiceId }
  }
}

// Get proforma invoice PDF - DEMO

export const GET_PROFORMA_INVOICE_PDF_TRIGGERED = 'GET_PROFORMA_INVOICE_PDF_TRIGGERED'
export const GET_PROFORMA_INVOICE_PDF_SUCCESS = 'GET_PROFORMA_INVOICE_PDF_SUCCESS'
export const GET_PROFORMA_INVOICE_PDF_FAILURE = 'GET_PROFORMA_INVOICE_PDF_FAILURE'

export function getProformaInvoicePdf(invoiceId: number): PromiseAction {
  const promise = ProformaInvoicesApi.getProformaInvoicePdf(invoiceId)
  return {
    onRequest: GET_PROFORMA_INVOICE_PDF_TRIGGERED,
    onSuccess: GET_PROFORMA_INVOICE_PDF_SUCCESS,
    onFailure: GET_PROFORMA_INVOICE_PDF_FAILURE,
    promise,
    payload: { invoiceId }
  }
}

// Post Monthly Volumes and Proforma Invoices for EIC

export const POST_MONTHLY_VOLUMES_TRIGGERED = 'POST_MONTHLY_VOLUMES_TRIGGERED'
export const POST_MONTHLY_VOLUMES_SUCCESS = 'POST_MONTHLY_VOLUMES_SUCCESS'
export const POST_MONTHLY_VOLUMES_FAILURE = 'POST_MONTHLY_VOLUMES_FAILURE'

export function postMonthlyVolumes(payload: IPostMonthlyVolumes): PromiseAction {
  const promise = ProformaInvoicesApi.postMonthlyVolumes(payload)
  return {
    onRequest: POST_MONTHLY_VOLUMES_TRIGGERED,
    onSuccess: POST_MONTHLY_VOLUMES_SUCCESS,
    onFailure: POST_MONTHLY_VOLUMES_FAILURE,
    promise,
    payload: { payload }
  }
}

// Generate Proforma Invoices for current month

export const POST_PROFORMA_INVOICES_GENERATE_TRIGGERED = 'POST_PROFORMA_INVOICES_GENERATE_TRIGGERED'
export const POST_PROFORMA_INVOICES_GENERATE_SUCCESS = 'POST_PROFORMA_INVOICES_GENERATE_SUCCESS'
export const POST_PROFORMA_INVOICES_GENERATE_FAILURE = 'POST_PROFORMA_INVOICES_GENERATE_FAILURE'

export function postProformaInvoicesGenerate(payload: IPostProformaInvoicesGenerate): PromiseAction {
  const promise = ProformaInvoicesApi.postProformaInvoicesGenerate(payload)
  return {
    onRequest: POST_PROFORMA_INVOICES_GENERATE_TRIGGERED,
    onSuccess: POST_PROFORMA_INVOICES_GENERATE_SUCCESS,
    onFailure: POST_PROFORMA_INVOICES_GENERATE_FAILURE,
    promise,
    payload: { payload }
  }
}

// Export proforma invoices list in xls
export const EXPORT_PROFORMA_INVOICES_TRIGGERED = 'EXPORT_PROFORMA_INVOICES_TRIGGERED'
export const EXPORT_PROFORMA_INVOICES_SUCCESS = 'EXPORT_PROFORMA_INVOICES_SUCCESS'
export const EXPORT_PROFORMA_INVOICES_FAILURE = 'EXPORT_PROFORMA_INVOICES_FAILURE'

export function exportProformaInvoices(filter: IProformaInvoicesFilter): PromiseAction {
  const promise = ProformaInvoicesApi.exportProformaInvoices(filter)
  return {
    onRequest: EXPORT_PROFORMA_INVOICES_TRIGGERED,
    onSuccess: EXPORT_PROFORMA_INVOICES_SUCCESS,
    onFailure: EXPORT_PROFORMA_INVOICES_FAILURE,
    promise
  }
}

// remove proforma invoice
export const REMOVE_PROFORMA_INVOICE_TRIGGERED = 'REMOVE_PROFORMA_INVOICE_TRIGGERED'
export const REMOVE_PROFORMA_INVOICE_SUCCESS = 'REMOVE_PROFORMA_INVOICE_SUCCESS'
export const REMOVE_PROFORMA_INVOICE_FAILURE = 'REMOVE_PROFORMA_INVOICE_FAILURE'

export function removeProformaInvoiceById(id: number): PromiseAction {
  const promise = ProformaInvoicesApi.removeProformaInvoiceById(id)
  return {
    onRequest: REMOVE_PROFORMA_INVOICE_TRIGGERED,
    onSuccess: REMOVE_PROFORMA_INVOICE_SUCCESS,
    onFailure: REMOVE_PROFORMA_INVOICE_FAILURE,
    promise
  }
}
