import { IMessage } from './distribution.interface'

// eslint-disable-next-line no-shadow
export enum PAYMENT_INVOICE_STATUS {
  NEW = 1,
  PAIRED = 2,
  ISSUED = 3,
  CANCELED = 4
}

export interface IPaymentInvoice {
  amount_invoiced_kwh: string
  billing_vat_eur: string
  billing_with_vat: string
  billing_without_vat_eur: string
  customer: string
  date_from: string
  date_to: string
  deduction_vat_eur: string
  deduction_with_vat: string
  deduction_without_vat_eur: string
  eic: string
  expected_delivery_period_end: string
  expected_delivery_period_start: string
  financial_settlement: string
  for_payment: string
  id: number
  payment_invoice_id: number
  proforma_invoice_id: number
  status: number
  tax_base_percent_0_percent_vat: number
  tax_base_percent_10_percent_vat: number
  tax_base_percent_20_percent_vat: number
  total_exclude_vat_eur: number
  total_include_vat_eur: number
  type_of_invoice: string
  vat_eur: number
}

export interface IPaymentInvoicesFilter {
  id?: number
  status?: number
  search?: string
  type_of_invoice?: string
  date_from?: string | Date
  date_to?: string | Date
  created_at_from?: string | Date
  created_at_to?: string | Date
}

export interface IPaymentInvoiceDetails {
  address_city: string
  address_number: string
  address_street: string
  address_zip_code: string
  another_fee_eur_kwh: string
  another_fee_zm: string
  billing_vat_eur: string
  billing_with_vat: string
  billing_without_vat_eur: string
  reserved_capacity: string
  contract_number: string
  created_at: string
  customer: string
  deduction_vat_eur: string
  deduction_with_vat: string
  deduction_without_vat_eur: string
  delivery: number
  distribution: number
  distribution_system: string
  distribution_tariff: string
  eic: string
  electricity_distribution_tariff_24_vt_eur_kwh: string
  electricity_distribution_tariff_24_vt_zm: string
  electricity_distribution_tariff_nt_eur_kwh: string
  electricity_distribution_tariff_nt_zm: string
  electricity_distribution_tariff_st_eur_kwh: string
  electricity_distribution_tariff_st_zm: string
  electricity_supply_tariff_eur_kwh: number
  electricity_supply_tariff_zm: string
  exceeding_mrk_eur_kwh: string
  exceeding_mrk_zm: string
  exceeding_rk_eur_kwh: string
  exceeding_rk_zm: string
  excise_duty: number
  excise_duty_tariff_eur_kwh: number
  excise_duty_tariff_zm: string
  financial_settlement: string
  fixed_monthly_fee: number
  fixed_monthly_tariff_eur_kwh: number
  fixed_monthly_tariff_zm: string
  for_payment: string
  id: number
  number_of_phases: number
  other_fees: number
  other_services_eur_om_month: number
  payment_to_njf_tariff_eur_kwh: number
  payment_to_njf_tariff_zm: string
  power_factor_surcharge_eur_kwh: string
  power_factor_surcharge_zm: string
  price_vt_eur_mwh: number
  price_fix?: string
  price_spot?: string
  zm_fix_kwh?: string
  zm_spot_kwh?: string
  electricity_supply_fix?: string
  electricity_supply_spot?: string
  fix_price_ratio?: number
  reserved_capacity_tariff_eur_kwh: number
  reserved_capacity_tariff_zm: string
  rzm_kwh: number
  status: number
  supply_of_reactive_electricity_eur_kwh: string
  supply_of_reactive_electricity_zm: string
  tariff_for_losses_tariff_eur_kwh: number
  tariff_for_losses_tariff_zm: string
  tariff_category: string
  tax_base_0_percent_vat: string
  tax_base_10_percent_vat: string
  tax_base_20_percent_vat: string
  total_exclude_vat_eur: number
  total_with_vat_eur: number
  tps_price_tariff_eur_kwh: number
  tps_tariff_zm: string
  tss_tariff_eur_kwh: number
  tss_tariff_zm: string
  type_of_invoice: string
  type_of_measurement: string
  vat_eur: number
  voltage_level: string
  zm_kwh: string
  zm_kwh_last_year: string
  max_reserved_capacity: string
  fixed_monthly_fee_cost: string
  fixed_monthly_fee_price: string
  transmission: string
  excise_duty_vat: string
  distribution_vat: string
  delivery_vat: string
  other_fees_vat: string
  date_from: string
  date_to: string
  proforma_invoices: { proforma: IPaymentInvoiceProformaDetails[] }
  counters: { counters: ICounter[] }
  distribution_items: { items: IDistributionItem[] }
  technical_info_items: { items: ITechnicalInfoItem[] }
  invoice?: IPaymentInvoiceDetailsInvoice
  message?: IMessage
}

export interface IPaymentInvoiceDetailsInvoice {
  id: number
  variable_symbol: string
  status: number
}

export interface IPaymentInvoiceProformaDetails {
  id: number
  vat: string
  due_date: string
  delivery_date: string
  amount_exclude_vat: string
  amount_include_vat: string
}

export interface ICounter {
  unit: string
  total: number
  losses: number
  date_to?: string
  date_from?: string
  end_state: number
  difference: number
  electromer: string
  multiplier: number
  counter_type: string
  initial_state: number
  calculated_data: number
}

export interface IDistributionItem {
  vat: number
  item: string
  unit: string
  price: number
  amount: number
  quantity: number
}

export interface ITechnicalInfoItem {
  item: string
  unit: string
  quantity: number
}

export const PAYMENT_INVOICE_COUNTER_PRODUCTS = {
  E_CINNY_ODBER_24: 'Činný odber 24',
  E_CINNY_ODBER_VT: 'Činný odber VT',
  E_CINNY_ODBER_NT: 'Činný odber NT',
  E_CINNY_ODBER_ST: 'Činný odber ST',
  E_MAXIM_ODBER_24: 'Max. činný výkon',
  E_R1JAL_ODBER_24: 'Jalový odber',
  E_R4JAL_DODAV_24: 'Jalová dodávka',
  CINNE_STRATY_VT: 'Činné stráty VT',
  CINNE_STRATY_NT: 'Činné stráty NT',
  CINNE_STRATY_VV: 'Činné stráty VV',
  CINNE_STRATY_VN: 'Činné stráty VN'
}

export const PAYMENT_INVOICE_COUNTER_UNITS = {
  E_CINNY_ODBER_24: 'kWh',
  E_CINNY_ODBER_VT: 'kWh',
  E_CINNY_ODBER_NT: 'kWh',
  E_CINNY_ODBER_ST: 'kWh',
  E_MAXIM_ODBER_24: 'kW',
  E_R1JAL_ODBER_24: 'kVARh',
  E_R4JAL_DODAV_24: 'kVARh',
  CINNE_STRATY_VT: 'kWh',
  CINNE_STRATY_NT: 'kWh',
  CINNE_STRATY_VV: 'kWh',
  CINNE_STRATY_VN: 'kWh'
}

export const PAYMENT_INVOICE_DISTRIBUTION_PRODUCTS = {
  DIST_FIXVYK: 'Rezervovaná kapacita',
  DIST_FIXOBD: 'Cena za distribúciu elektriny za obdobie',
  DIST_VARIABIL_24: 'Distribúcia elektriny vrátane prenosu elektriny',
  DIST_VARIABIL_ST: 'Distribúcia elektriny vrátane prenosu elektriny - ST',
  DIST_VARIABIL_VT: 'Distribúcia elektriny vrátane prenosu elektriny - VT',
  DIST_VARIABIL_NT: 'Distribúcia elektriny vrátane prenosu elektriny - NT',
  DIST_STRATY: 'Tarifa za straty pri distribúcii elektriny',
  DIST_PREKRK: 'Tarifa za prekročenie RK',
  DIST_PREKMRK: 'Tarifa za prekročenie MRK',
  DIST_SYSLUZ: 'Tarifa za systémové služby',
  DIST_PREVSYS: 'Tarifa za prevádzkovanie systému',
  DIST_JADRO: 'Odvod do Národného jadrového fondu',
  DIST_POPRTV: 'Poplatok za rezervovaný transformačný výkon',
  DIST_NO_PRIKON: 'Platba za nemeraný odber podľa inštalovaného príkonu',
  DIST_NO_POCET: 'Platba za nemeraný odber podľa počtu odberných miest',
  DIST_UCINNIK_24: 'Tarifa za nedodržanie zmluvnej hodnoty účinníka',
  DIST_UCINNIK_ST: 'Tarifa za nedodržanie zmluvnej hodnoty účinníka ST',
  DIST_UCINNIK_VT: 'Tarifa za nedodržanie zmluvnej hodnoty účinníka VT',
  DIST_UCINNIK_NT: 'Tarifa za nedodržanie zmluvnej hodnoty účinníka NT',
  DIST_DODJAL_24: 'Tarifa za dodávku kapacitnej zložky jalovej energie'
}

export const PAYMENT_INVOICE_DISTRIBUTION_UNITS = {
  DIST_FIXVYK: 'A',
  DIST_FIXOBD: '',
  DIST_VARIABIL_24: 'kWh',
  DIST_VARIABIL_ST: 'kWh',
  DIST_VARIABIL_VT: 'kWh',
  DIST_VARIABIL_NT: 'kWh',
  DIST_STRATY: 'kWh',
  DIST_PREKRK: '',
  DIST_PREKMRK: '',
  DIST_SYSLUZ: 'kWh',
  DIST_PREVSYS: 'kWh',
  DIST_JADRO: 'kWh',
  DIST_POPRTV: 'mesiac',
  DIST_NO_PRIKON: '',
  DIST_NO_POCET: '',
  DIST_UCINNIK_24: 'num',
  DIST_UCINNIK_ST: 'num',
  DIST_UCINNIK_VT: 'num',
  DIST_UCINNIK_NT: 'num',
  DIST_DODJAL_24: 'kVARh'
}

export const PAYMENT_INVOICE_TECHNICAL_INFORMATION = {
  DOH_RK: 'Rezerovavná kapacita',
  DOH_MRK: 'Maximálna rezerovavná kapacita',
  MAXIM_ODBER_24: 'Maximálny nameraný výkon',
  TG_FI_CITATEL_24: 'Čitateľ pre výpočet účinníka',
  TG_FI_MENOVATEL_24: 'Menovateľ pre výpočet účinníka',
  DOH_COS_FI: 'Dohodnutý TG PHI'
}

export interface IPaymentInvoiceCounter {
  date_from: string | Date
  date_to: string | Date
  counter_type: string
  initial_state: number
  end_state: number
  difference: number
  multiplier: number
  calculated_data: number
  unit: string
  electromer: string
}

export interface IPaymentInvoicesDistributionItem {
  item: string
  quantity: number
  unit: string
  price: number
  amount: number
  date_from: string | Date
  date_to: string | Date
}

export interface IPaymentInvoicesTechnicalInfoItem {
  item: string
  quantity: number
  unit: string
}

export interface IPaymentInvoiceCreatePayload {
  eic: string
  date_from: string | Date
  date_to: string | Date
  counters: IPaymentInvoiceCounter[]
  distribution_items: IPaymentInvoicesDistributionItem[]
  technical_info_items: IPaymentInvoicesTechnicalInfoItem[]
}
