interface Options {
  currency?: string | null
  format?: string
  decimalPoints?: number
  minimumFractionDigits?: number
}

export const displayPrice = (price: string | number, options?: Options) => {
  if (price === null || price === undefined || isNaN(Number(price)) || price === '') return ''
  const currency = options?.currency || 'EUR'
  const format = options?.format || 'en-US'
  const decimalPoints = options?.decimalPoints ?? 2

  const formattedPrice = new Intl.NumberFormat(format, {
    style: 'currency',
    currency: currency || 'EUR',
    minimumFractionDigits: options?.minimumFractionDigits || decimalPoints,
    maximumFractionDigits: decimalPoints
  }).format(parseFloat(price.toString()))
  return currency ? formattedPrice : formattedPrice.replace('€', '')
}

export const round = (value: number | string | undefined, decimals = 2): number => {
  if (value === undefined) return 0
  let number = value
  if (typeof number === 'string') number = Number(number)
  const power = Math.pow(10, decimals)
  const temp = Number((Math.abs(number) * power).toPrecision(15))
  return (Math.round(temp) / power) * Math.sign(number) || 0
}

export const displayFormattedNumber = (num: number | string | undefined | null): string => {
  if (num === null || num === undefined || isNaN(Number(num))) return ''
  return round(num)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
