import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { user$ } from 'src/redux/selectors'

import { UserPermissions } from '../interfaces'

/**
 * Custom hook to check user permissions.
 *
 * This hook retrieves the user from the Redux store and provides a function
 * to check if the user has a specific permission.
 *
 * @returns {(permission: UserPermissions) => boolean} A function that takes a permission
 * and returns `true` if the user has the permission, otherwise `false`.
 *
 * @example
 * const permissionsGuard = usePermissionsGuard();
 *
 * if (permissionsGuard(UserPermissions.financials)) {
 *   console.log("User has access to financials");
 * }
 */
const usePermissionsGuard = () => {
  const user = useSelector(user$)

  return useMemo(() => (permission: UserPermissions) => user?.permissions.includes(permission) ?? false, [user])
}

export default usePermissionsGuard
