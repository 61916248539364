import React from 'react'
import { AppPage, Grid, Typography } from '@otion-core/sandy'
import { useTranslation } from 'react-i18next'

import { AdministrationIcon, ElectricityIcon, FinancialsIcon, GasIcon, TradingIcon } from 'src/assets/icons'
import { UserPermissions } from 'src/shared/interfaces'
import { usePermissionsGuard } from 'src/shared/hooks'

import { HeaderRightAdornment } from '../../components'
import * as S from './styles'

const HomePage = () => {
  const { t } = useTranslation()
  const permissionsGuard = usePermissionsGuard()

  const gridItems = [
    <Grid.Item key='grid_item_1'>
      <S.Toolbox
        to='/administration'
        icon={<AdministrationIcon />}
        text={
          <Typography variant='heading' size='small' weight='medium' inline>
            {t('ADMINISTRATION')}
          </Typography>
        }
        size='large'
        disabled
      />
    </Grid.Item>,
    <Grid.Item key='grid_item_2'>
      <S.Toolbox
        backgroundColor='green.light'
        to='/electricity'
        icon={<ElectricityIcon />}
        text={
          <Typography variant='heading' size='small' weight='medium' inline>
            {t('ELECTRICITY')}
          </Typography>
        }
        size='large'
      />
    </Grid.Item>,
    <Grid.Item key='grid_item_3'>
      <S.Toolbox
        backgroundColor='teal.light'
        to='/trade'
        icon={<TradingIcon />}
        text={
          <Typography variant='heading' size='small' weight='medium' inline>
            {t('TRADE')}
          </Typography>
        }
        size='large'
      />
    </Grid.Item>,
    permissionsGuard(UserPermissions.financials) && (
      <Grid.Item key='grid_item_4'>
        <S.Toolbox
          backgroundColor='orange.light'
          to='/financials'
          icon={<FinancialsIcon />}
          text={
            <Typography variant='heading' size='small' weight='medium' inline>
              {t('FINANCIALS')}
            </Typography>
          }
          size='large'
        />
      </Grid.Item>
    ),
    <Grid.Item key='grid_item_5'>
      <S.Toolbox
        backgroundColor='blue.light'
        to='/gas'
        icon={<GasIcon />}
        text={
          <Typography variant='heading' size='small' weight='medium' inline>
            {t('GAS')}
          </Typography>
        }
        size='large'
      />
    </Grid.Item>
  ]

  return (
    <AppPage title={t('HOME')} headerTitle={t('HOME')} headerRightAdornment={<HeaderRightAdornment />} hideBackButton>
      <S.PolygonalGrid>{gridItems.filter(gridItem => gridItem).map(gridItemClean => gridItemClean)}</S.PolygonalGrid>
    </AppPage>
  )
}

HomePage.displayName = 'HomePage'
export default HomePage
