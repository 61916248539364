import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IPagination, IRememberedListProps, IRootState, PromiseAction, TableSort } from '../interfaces'
import { useNotifications } from './index'

interface UsePaginationOptions<Entity = any, FilterValues = any> {
  pageSize?: number
  initialFilter: FilterValues
  initialSort: TableSort<Entity>
  hookDeps?: any[]
}

/**
 * React hook for managing pagination in a list view.
 * @param fetchAction - Action function to retrieve data for the list.
 * @param listSelector - Specific list selector to use in reducer for managing the list data.
 * @param options - Options for the hook.
 *  initialFilter - Initial filter values for the list.
 *  initialSort - Initial sort state for the list.
 *  hookDeps - Variables that can trigger reloading data (use in useEffect hook deps)
 * @returns An object with properties for managing the pagination state:
 *  loading: A boolean value indicating whether the list data is being loaded.
 *  setLoading: A function that can be used to explicitly set the loading state.
 *  onChangePage: A function that can be used to change the current page. It takes two arguments: pageIndex (the index of the new page) and pageSize (the number of items per page).
 *  onChangeSort: A function that can be used to change the sort state. It takes a single argument: params (an object containing the new sort parameters).
 *  updateFilter: A function that can be used to update the filter state. It takes a single argument: filter (the new filter values).
 *  reloadData: A function that can be used to reload the list data.
 *  totalCount: The total number of items in the list.
 *  data: The current page of items in the list.
 *  pagination: An object containing information about the current pagination state, including the current page, total pages, and other metadata.
 *  filterState: The current filter values.
 */
function usePagination<Entity, FilterValues>(
  fetchAction: (filter: FilterValues, sort: TableSort<Entity> | undefined, page: number, size: number) => PromiseAction,
  listSelector: (state: IRootState) => any,
  options: UsePaginationOptions<Entity, FilterValues>
) {
  const dispatch = useDispatch()
  const { showError } = useNotifications()

  const reducerList = useSelector(listSelector)
  const totalCount: number = reducerList.totalCount
  const data: Entity[] = reducerList.data?.items
  const pagination: IPagination<Entity> = reducerList.data?.pagination
  const rememberedListProps: IRememberedListProps = reducerList.rememberedListProps

  const [filterState, setFilter] = useState<FilterValues>(rememberedListProps?.filter || options.initialFilter)
  const [sortState, setSort] = useState<TableSort<Entity>>(rememberedListProps?.sort || options.initialSort)
  const [loading, setLoading] = useState(false)

  const getData = async (
    filter: FilterValues,
    sort: TableSort<Entity>,
    pageIndex = 1,
    pageSize = options.pageSize || 10
  ) => {
    setLoading(true)
    const res = await dispatch(fetchAction(filter, sort, pageIndex, pageSize))
    setLoading(false)
    if (!res?.data) showError('Nepodarilo sa načítať údaje')
  }

  useEffect(() => {
    getData(filterState, sortState, rememberedListProps?.page, rememberedListProps?.size)
  }, options?.hookDeps || [])

  const onChangePage = (pageIndex: number, pageSize?: number) => {
    return getData(filterState, sortState, pageIndex, pageSize)
  }

  const onChangeSort = (params: TableSort<Entity>) => {
    setSort(params)
    return getData(filterState, params)
  }

  const updateFilter = (filter: FilterValues, customSort?: TableSort<Entity>) => {
    setFilter(filter)
    if (customSort) setSort(customSort)
    return getData(filter, customSort || sortState)
  }

  const reloadData = () => getData(filterState, sortState)

  return {
    loading,
    setLoading,
    onChangePage,
    onChangeSort,
    updateFilter,
    reloadData,
    totalCount,
    data,
    pagination,
    filterState,
    sortState
  }
}

export default usePagination
