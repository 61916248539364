/* eslint-disable max-lines */
import moment from 'moment'
import { generatePaginationPayload } from 'src/lib/paginationPayload'
import {
  IPaginationResponse,
  TableSort,
  IBillsFilter,
  IBill,
  IBankAccountFilter,
  IBankAccount,
  IBankAccountStatus,
  IPairManuallyPayload,
  IFinancialBillHistory,
  IMoveBalancePayload,
  IExpenseCreatePayload,
  IExpenseUploadFile,
  IIncomeCreatePayload,
  IUpdateDtoBill,
  IUpdateDtoBankAccount,
  IBasicInfoCribisResponseDataDto,
  ICreateExpenseForBankAccountPayload,
  IBankAccountCreatePayload,
  ICreateCopyBillPayload
} from 'src/shared/interfaces'
import { DATE_FORMAT_DB } from 'src/shared/constants'

import Http from './api'

export const getAllBankAccount = (
  filter: IBankAccountFilter,
  sort: TableSort<IBankAccount> | undefined,
  page: number,
  size = 10
) => {
  const payload = generatePaginationPayload({
    filter: {
      turnover_date: [
        filter.turnover_date_start ? moment(filter.turnover_date_start).format(DATE_FORMAT_DB) : undefined,
        filter.turnover_date_end ? moment(filter.turnover_date_end).format(DATE_FORMAT_DB) : undefined
      ],
      'bank_accounts.amount': [filter.amount_from ?? undefined, filter.amount_to ?? undefined],
      'bank_accounts.status': filter.status
    },
    searchBy: ['bank_accounts.variable_symbol', 'customer', 'title'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IBankAccount>>('/bank-accounts', payload)
}

export const getBankAccountDetail = (id: number) => {
  return Http.get<IBankAccount>(`/bank-accounts/${id}`)
}

export const getBankAccountStatuses = () => {
  return Http.get<IBankAccountStatus[]>('/bank-accounts/statuses')
}

export const getBankAccountSuggestions = (billId: number) => {
  return Http.get<IBankAccount[]>(`/bank-accounts/unpaired/${billId}`)
}
export const getBillSuggestions = (billId: number) => {
  return Http.get<IBill[]>(`/bills/suggestions/${billId}`)
}

export const getBillSuggestionsProformas = (billId: number) => {
  return Http.get<IBill[]>(`/bills/suggestions-proformas/${billId}`)
}

export const getAllIncomes = (filter: IBillsFilter, sort: TableSort<IBill> | undefined, page: number, size = 10) => {
  const payload = generatePaginationPayload({
    filter: {
      delivery_date: [
        filter.delivery_date_start ? moment(filter.delivery_date_start).format(DATE_FORMAT_DB) : undefined,
        filter.delivery_date_end ? moment(filter.delivery_date_end).format(DATE_FORMAT_DB) : undefined
      ],
      issue_date: [
        filter.issue_date_start ? moment(filter.issue_date_start).format(DATE_FORMAT_DB) : undefined,
        filter.issue_date_end ? moment(filter.issue_date_end).format(DATE_FORMAT_DB) : undefined
      ],
      due_date: [
        filter.due_date_start ? moment(filter.due_date_start).format(DATE_FORMAT_DB) : undefined,
        filter.due_date_end ? moment(filter.due_date_end).format(DATE_FORMAT_DB) : undefined
      ],
      paid_at: [
        filter.paid_at_start ? moment(filter.paid_at_start).format(DATE_FORMAT_DB) : undefined,
        filter.paid_at_end ? moment(filter.paid_at_end).format(DATE_FORMAT_DB) : undefined
      ],
      total_amount: [filter.total_amount_from ?? undefined, filter.total_amount_to ?? undefined],
      amount_without_vat: [filter.amount_without_vat_from ?? undefined, filter.amount_without_vat_to ?? undefined],
      vat: [filter.vat_from ?? undefined, filter.vat_to ?? undefined],
      balance:
        filter.balance_from || filter.balance_to
          ? [filter.balance_from ?? undefined, filter.balance_to ?? undefined]
          : filter.balance,
      'bills.status': filter.status,
      partner_id: filter.partner_id
    },
    searchBy: [
      'customers.customer',
      'customers.company_id',
      'bills.variable_symbol',
      'invoice_number',
      'subscriber',
      'bills.description',
      'filename'
    ],
    sumColumns: ['balance', 'amount_without_vat', 'vat', 'total_amount'],
    search: filter.search,
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IBill>>('/bills/INCOME', payload)
}

export const getAllExpenses = (filter: IBillsFilter, sort: TableSort<IBill> | undefined, page: number, size = 10) => {
  const payload = generatePaginationPayload({
    filter: {
      delivery_date: [
        filter.delivery_date_start ? moment(filter.delivery_date_start).format(DATE_FORMAT_DB) : undefined,
        filter.delivery_date_end ? moment(filter.delivery_date_end).format(DATE_FORMAT_DB) : undefined
      ],
      issue_date: [
        filter.issue_date_start ? moment(filter.issue_date_start).format(DATE_FORMAT_DB) : undefined,
        filter.issue_date_end ? moment(filter.issue_date_end).format(DATE_FORMAT_DB) : undefined
      ],
      due_date: [
        filter.due_date_start ? moment(filter.due_date_start).format(DATE_FORMAT_DB) : undefined,
        filter.due_date_end ? moment(filter.due_date_end).format(DATE_FORMAT_DB) : undefined
      ],
      paid_at: [
        filter.paid_at_start ? moment(filter.paid_at_start).format(DATE_FORMAT_DB) : undefined,
        filter.paid_at_end ? moment(filter.paid_at_end).format(DATE_FORMAT_DB) : undefined
      ],
      total_amount: [filter.total_amount_from ?? undefined, filter.total_amount_to ?? undefined],
      amount_without_vat: [filter.amount_without_vat_from ?? undefined, filter.amount_without_vat_to ?? undefined],
      vat: [filter.vat_from ?? undefined, filter.vat_to ?? undefined],
      balance:
        filter.balance_from || filter.balance_to
          ? [filter.balance_from ?? undefined, filter.balance_to ?? undefined]
          : filter.balance,
      'bills.status': filter.status,
      partner_id: filter.partner_id
    },
    searchBy: [
      'customers.customer',
      'customers.company_id',
      'bills.variable_symbol',
      'invoice_number',
      'subscriber',
      'bills.description',
      'filename'
    ],
    search: filter.search,
    sumColumns: ['balance', 'amount_without_vat', 'vat', 'total_amount'],
    sort,
    page,
    size
  })
  return Http.post<IPaginationResponse<IBill>>('/bills/EXPENSE', payload)
}

export const getBillDetail = (id: number) => {
  return Http.get<IBill>(`/bills/${id}`)
}

export const getBillHistoryByBill = (id: number) => {
  return Http.get<IBill>(`/bills-history/by-bill/${id}`)
}

export const getBillHistoryByBankAccount = (id: number) => {
  return Http.get<IBill>(`/bills-history/by-bank-account/${id}`)
}

export const postPairManually = (payload: IPairManuallyPayload) => {
  return Http.post<IFinancialBillHistory>('/bills-history/pair-manually', payload)
}

export const postMoveBalance = (payload: IMoveBalancePayload) => {
  return Http.post<IFinancialBillHistory>('/bills-history/move-balance', payload)
}

export const postAccountProformaBill = (payload: IMoveBalancePayload) => {
  return Http.post<IFinancialBillHistory>('/bills-history/account-proforma', payload)
}

export const createExpenseBill = (payload: IExpenseCreatePayload) => {
  const formData = new FormData()
  if (payload.files && payload.files.length) {
    for (const file of payload.files) {
      formData.append('files', file, file.name)
    }
  }
  Object.entries(payload).forEach(([key, value]) => {
    if (key !== 'files' && value !== undefined) {
      formData.append(key, value)
    }
  })
  const headers = { 'Content-Type': 'multipart/form-data' }
  return Http.post<IBill>('/bills/generate', formData, undefined, { headers } as any)
}

export const createIncomeBill = (payload: IIncomeCreatePayload) => {
  const formData = new FormData()
  if (payload.files && payload.files.length) {
    for (const file of payload.files) {
      formData.append('files', file, file.name)
    }
  }
  Object.entries(payload).forEach(([key, value]) => {
    if (key !== 'files' && value !== undefined) {
      formData.append(key, value)
    }
  })
  const headers = { 'Content-Type': 'multipart/form-data' }
  return Http.post<IBill>('/bills/generate', formData, undefined, { headers } as any)
}

export const removeFileFromBill = (billId: number, fileId: number) => {
  return Http.delete(`/bills/${billId}/${fileId}`)
}

export const uploadFilesToBill = (billId: number, payload: IExpenseUploadFile) => {
  const formData = new FormData()
  if (payload.files && payload.files.length) {
    for (const file of payload.files) {
      formData.append('files', file, file.name)
    }
  }
  const headers = { 'Content-Type': 'multipart/form-data' }
  return Http.post<IBill>(`/bills/expense/upload/${billId}`, formData, undefined, { headers } as any)
}
export const removeBill = (id: number) => {
  return Http.delete(`/bills/${id}`)
}

export const removeBillHistoryItem = (id: number) => {
  return Http.delete(`/bills-history/${id}`)
}

export const updateBillDetails = (id: number, payload: Partial<IUpdateDtoBill>) => {
  return Http.patch<IBill>(`/bills/${id}`, payload)
}

export const updateBankAccount = (id: number, payload: Partial<IUpdateDtoBankAccount>) => {
  return Http.patch<IBankAccount>(`/bank-accounts/${id}`, payload)
}

export const recalculateBillBalance = (id: number) => {
  return Http.get<IBill>(`/bills/recalculate-balance/${id}`)
}

export const getCompanyInfoFromCribis = (ico: string) => {
  return Http.get<IBasicInfoCribisResponseDataDto>(`/cribis/${ico}`)
}

export const exportBankAccounts = (filter: IBankAccountFilter) => {
  const payload = generatePaginationPayload({
    filter: {
      turnover_date: [
        filter.turnover_date_start ? moment(filter.turnover_date_start).format(DATE_FORMAT_DB) : undefined,
        filter.turnover_date_end ? moment(filter.turnover_date_end).format(DATE_FORMAT_DB) : undefined
      ],
      'bank_accounts.amount': [filter.amount_from ?? undefined, filter.amount_to ?? undefined],
      'bank_accounts.status': filter.status
    },
    searchBy: ['bank_accounts.variable_symbol', 'customer'],
    search: filter.search,
    sort: undefined,
    page: 1,
    size: 10
  })
  return Http.getFile<unknown>(`/bank-accounts/xls/export/?filter=${JSON.stringify(payload)}`)
}

export const exportExpenses = (filter: IBillsFilter) => {
  const payload = generatePaginationPayload({
    filter: {
      delivery_date: [
        filter.delivery_date_start ? moment(filter.delivery_date_start).format(DATE_FORMAT_DB) : undefined,
        filter.delivery_date_end ? moment(filter.delivery_date_end).format(DATE_FORMAT_DB) : undefined
      ],
      total_amount: [filter.total_amount_from ?? undefined, filter.total_amount_to ?? undefined],
      balance: filter.balance,
      'bills.status': filter.status
    },
    searchBy: ['customers.customer', 'variable_symbol', 'subscriber', 'description', 'filename'],
    search: filter.search,
    sort: undefined,
    page: 1,
    size: 10
  })
  return Http.getFile<unknown>(`/bills/xls/export/EXPENSE?filter=${JSON.stringify(payload)}`)
}
export const exportIncomes = (filter: IBillsFilter) => {
  const payload = generatePaginationPayload({
    filter: {
      delivery_date: [
        filter.delivery_date_start ? moment(filter.delivery_date_start).format(DATE_FORMAT_DB) : undefined,
        filter.delivery_date_end ? moment(filter.delivery_date_end).format(DATE_FORMAT_DB) : undefined
      ],
      total_amount: [filter.total_amount_from ?? undefined, filter.total_amount_to ?? undefined],
      balance: filter.balance,
      'bills.status': filter.status
    },
    searchBy: ['customers.customer', 'variable_symbol', 'subscriber', 'description', 'filename'],
    search: filter.search,
    sort: undefined,
    page: 1,
    size: 10
  })
  return Http.getFile<unknown>(`/bills/xls/export/INCOME?filter=${JSON.stringify(payload)}`)
}

export const exportUnpaidIncomes = () => {
  return Http.getFile<unknown>(`/bills/xls/export/incomes/unpaid`)
}

export const createExpenseForBankAccount = (payload: ICreateExpenseForBankAccountPayload) => {
  return Http.get<IFinancialBillHistory>(`/bank-accounts/add-expense-for-bank-account/${payload.bankAccountId}`)
}

export const createBankAccount = (payload: IBankAccountCreatePayload) => {
  return Http.post<IBankAccount>('/bank-accounts/create', payload)
}

export const createCopyBill = (billId: number, payload: ICreateCopyBillPayload) => {
  return Http.post<IBankAccount>(`/bills/copy/${billId}`, payload)
}
