import React from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'

import { useNotifications } from 'src/shared/hooks'

import { Layout, LayoutProps } from '../'

interface Props {
  layout?: React.FC<LayoutProps>
  component: React.ComponentType
  isAnonym?: boolean
  isClientRoute?: boolean
  isTraderRoute?: boolean
  isAllowed?: boolean
}

const LayoutRoute = ({
  layout = Layout,
  isAnonym = false,
  isClientRoute = false,
  isTraderRoute = false,
  isAllowed = true,
  component
}: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { showError } = useNotifications()

  if (!isAllowed) {
    showError(t('accessDenied'))
    if (window.history?.length > 1) {
      navigate(-1)
      return null
    }
    return <Navigate to='/' />
  }

  return React.createElement(layout, { isAnonym, isClientRoute, isTraderRoute }, React.createElement(component))
}

LayoutRoute.displayName = 'LayoutRoute'
export default LayoutRoute
