import { IPod } from './pod.interface'

export interface IMeterValuesDetails {
  id: number
  pod_id: number
  pod: IPod
  gas_meter: string
  gas_meter_value: string
  gas_meter_value_002: string
  gas_meter_value_003: string
  gas_meter_value_004: any
  gas_meter_value_005: any
  gas_meter_value_006: any
  date_of_reading: string
  planned_date_of_reading: string
  reason: string
  planned_type_of_reading: string
  status: string
  type_of_reading: string
  activity_status: string
  message_subject?: string
}

export interface IMeterValues {
  id: number
  pod_id: number
  pod_pod: string
  pod_delivery_start: string
  pod_delivery_end: string
  pod_status: string
  pod_address_street: string
  pod_address_number: string
  pod_address_zip_code: string
  pod_address_city: string
  pod_rate: string
  pod_created_at: string
  pod_created_by: number
  pod_dmm: number
  pod_updated_at: string
  pod_updated_by: number
  pod_tdd: any
  pod_distribution_system: string
  gas_meter: string
}

export interface IMeterValuesFilter {
  search?: string
  date_of_reading_from?: string
  date_of_reading_to?: string
  status?: number
}

export interface IMeterValuesDetailsFilter {
  search?: string
  date_of_reading_from?: string
  date_of_reading_to?: string
  status?: number
}

// Z_2
export const GasMeterReasonEnum = {
  '01': 'Periodický odpočet',
  '02': 'Priebežný odpočet so zúčtovaním',
  '09': 'Priebežný odpočet bez zúčtovania',
  10: 'Kontrolný odpočet',
  21: 'Odpočet na montáž so zúčtovaním',
  22: 'Odpočet na demontáž so zúčtovaním'
}

// Z_7
export const GasMeterStatusEnum = {
  0: 'Príkaz na odpočet vytvorený',
  1: 'Zúčtovateľné',
  2: 'Blokované automaticky',
  3: 'Blokované referentom',
  4: 'Uvoľnené referentom',
  5: 'Kontrolované nezávisle',
  7: 'Zúčtované'
}

// Z_9
export const GasMeterActivityStatusEnum = {
  1: 'Aktívny'
}

// Z_5
export const GasMeterPlannedTypeOfReadingEnum = {
  '01': 'Odpočet dodaný PDS',
  '02': 'Odpočet zákazníkom',
  '03': 'Automatický odhad',
  '04': 'Odpočet odvodený',
  ON: 'Odpočet pri zmene dodávateľa'
}

// Z_8
export const GasMeterTypeOfReadingEnum = {
  '01': 'Fyzický odpočet vykonaný pracovníkom PDS',
  '03': 'Automatický odhad',
  '04': 'Odvodený odpočet',
  13: 'Automatický odhad - po uplynutí overenia',
  95: 'Automatický odhad - hromadný',
  96: 'Fyzický odpočet z MPDR',
  98: 'Odhad odpočtov - SAP',
  99: 'Fyzický odpočet od dodávateľa odpočtov',
  B1: 'Samoodpočet',
  B2: 'Oprava samoodpočtu',
  D1: 'Fyzické obnovenie dodávky ZP',
  DS: 'Doúčtovanie spotreby',
  E1: 'Fyzické prerušenie dodávky ZP',
  E2: 'Neoprávnený odber ext.',
  E3: 'Neoprávnený odber int.',
  H1: 'REME Iná reklamácia',
  H2: 'Kontrola merania pri reklamácii meradla - kontrola odpočtu pri reklamácii',
  H3: 'EXT_Reklamácia skutočného dátumu odpočtu',
  H4: 'EXT_Reklamácia fyzického odpočtu - odpočet odberateľa',
  H6: 'EXT_Reklamácia odhadu stavu - odpočet odberateľa',
  H7: 'EXT_Reklamácia dopočtu spotreby - zaseknutý číselník',
  HF: 'INT_Reklamácia fyzického odpočtu - nahradenie odhadom',
  HO: 'INT_Reklamácia odhadu - nový odhad',
  HS: 'EXT_Úprava spotreby po preskúšaní',
  HX: 'Odpočet preverovaný dodávateľom odpočtov',
  HY: 'Odpočet preverovaný SPP-D',
  K1: 'Oprava odpočtu pri potvrdených úniku plynu',
  KL: 'Samoodpočet nahlásený cez Call-centrum',
  W1: 'Samoodpočet nahlásený cez Web',
  W2: 'Oprava samoodpočtu Web',
  L1: 'Prerušenie odberu z dôvodu rekonštrukcie',
  L2: 'Obnovenie odberu z dôvodu rekonštrukcie',
  ON: 'Odpočet pri zmene dodávateľa pre nového obchodníka',
  OS: 'Odpočet pri zmene dodávateľa pre starého obchodníka',
  SO: 'Systémové obnovenie distribúcie',
  SP: 'Systémové prerušenie distribúcie',
  XX: 'Dočasný odpočet',
  A2: 'Oprava samoodpočtu aplikácia',
  AE: 'Odpočet zaslaný cez aplikáciu po OCR editovaný',
  AO: 'Odpočet zaslaný cez aplikáciu zosnímaný OCR bez editácie'
}
