import { ProductTypeEnum } from './leads.interface'
import { CommodityTypeEnum } from './contracts.interface'

// eslint-disable-next-line no-shadow
export enum OfferStatusEnum {
  NEW = 'NEW',
  CHECKED = 'CHECKED',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED'
}

export interface ICreateOfferPayload {
  lead_id: string
  rzm: number
  date_from: string
  date_to: string
  price_type: ProductTypeEnum
  fix_price?: number
  spot_add_price?: number
  combi_ratio_fix?: number
  valid_until: string
  issue_date: string
  commodity_type: CommodityTypeEnum
}

export interface IOffer {
  customer?: string
  id: string
  lead_id: string
  rzm: number
  date_from: string
  date_to: string
  price_type: ProductTypeEnum
  valid_until: string
  issue_date: string
  trader_id: string
  trader_name: string
  trader_phone: string
  trader_email: string
  created_at: string
  updated_at: string
  status: OfferStatusEnum
  company_address_city: string
  company_address_street: string
  company_address_zip: string
  fix_price?: number
  spot_add_price?: number
  combi_ratio_fix?: number
  commodity_type: CommodityTypeEnum
  deleted_at?: string
}

export interface IOffersFilter {
  search?: string
}
