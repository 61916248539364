/* eslint-disable no-shadow */
import { EicReservedCapacityType, EicSmallBusinessStatus } from './eic.interface'

export enum SeqEntryName {
  E_CINNY_ODBER_24 = 'E_CINNY_ODBER_24',
  E_CINNY_ODBER_ST = 'E_CINNY_ODBER_ST',
  E_CINNY_ODBER_VT = 'E_CINNY_ODBER_VT',
  E_CINNY_ODBER_NT = 'E_CINNY_ODBER_NT'
}

export interface ISeqEntry {
  sequenceNumber: number
  seqEntry: SeqEntryName
  quantity: number
}

export enum MessageCodeEnum {
  CHANGE_OF_SUPPLIER = '101',
  MOVE_IN = '431',
  COMPLAINT = '401',
  MOVE_OUT = '411',
  CHANGE_OF_PARAMETERS = '421',
  PDS_SERVICE = '441',
  TRANSFER_REQUEST = '491',
  PRODUCER_CHANGE_OF_SUPPLIER = '10B',
  PRODUCER_MOVE_IN = '43B',
  PRODUCER_MOVE_OUT = '41B'
}

export enum ActionTypesEnum {
  CHANGE_OF_SUPPLIER = 'CHANGE_OF_SUPPLIER',
  MOVE_IN = 'MOVE_IN',
  MOVE_OUT = 'MOVE_OUT',
  CHANGE_OF_PARAMETERS = 'CHANGE_OF_PARAMETERS',
  DELIVERY_POINT_TRANSFER = 'DELIVERY_POINT_TRANSFER',
  PDS_SERVICE = 'PDS_SERVICE',
  COMPLAINTS = 'COMPLAINTS'
}

export enum ActionStatusEnum {
  INCOMPLETE_DATA = 1,
  READY_FOR_SEND,
  SENT,
  DELIVERED,
  UNPROCESSABLE_REQUEST,
  PROCESSED,
  DECLINED,
  CANCEL_DECLINED,
  CANCELED,
  ACCEPTED,
  PAIRED,
  NOT_PAIRED
}

export enum AgreeTypeDescription {
  INDIVIDUAL_AGREEMENT = 'E01', // Agreement between the customer and PDS (individual agreement)
  COMBINED_AGREEMENT = 'E02', // Agreement between the supplier and PDS (combined agreement)
  PRODUCER_AGREEMENT = 'E03', // Agreement between the producer and PDS
  PROCESSING_TYPE = 'SOT' // Processing type for projects (used with 'SO')
}

export enum ProcessType {
  UN102 = 'UN102',
  UN104 = 'UN104',
  UN121 = 'UN121',
  UN124 = 'UN124',
  UN137 = 'UN137',
  UN138 = 'UN138',
  UN139_1 = 'UN139_1',
  UN139_2 = 'UN139_2',
  UN139_3 = 'UN139_3',
  UN148 = 'UN148',
  UN150 = 'UN150',
  UN191 = 'UN191',
  UN192 = 'UN192',
  UN193 = 'UN193',
  A001 = 'A001',
  A002 = 'A002',
  A003 = 'A003',
  A004 = 'A004',
  A005 = 'A005',
  A006 = 'A006',
  A007 = 'A007',
  A008 = 'A008',
  A009 = 'A009',
  A010 = 'A010',
  A011 = 'A011',
  A012 = 'A012',
  A013 = 'A013',
  A014 = 'A014',
  A015 = 'A015'
}

// ----------------------- Message 101 (Change of Supplier) -----------------------
export interface ChangeOfSupplierAction {
  eic: string
  agreeTypeDescription: AgreeTypeDescription
}

// -----------------------Message 422 (Change of Parameters) -----------------------
export enum ChangeOfParametersProcessType {
  CHANGE_RESERVED_CAPACITY_KW = ProcessType.UN138,
  CHANGE_RESERVED_CAPACITY_A = ProcessType.UN148,
  CHANGE_TARIFF = ProcessType.UN139_1,
  CHANGE_RATE_AND_TARIFF = ProcessType.UN139_2,
  CHANGE_RATE = ProcessType.UN139_3,
  CHANGE_CUSTOMER_DATA = ProcessType.UN137,
  CHANGE_VULNERABLE_CUSTOMER = ProcessType.UN150
}

// UN137
export interface ChangeParametersActionUn137 {
  eic: string
  processType: ProcessType.UN137
  pdsCustomerName?: string
  pdsCustomerStreet?: string
  pdsCustomerStreetNumber?: string
  pdsCustomerCity?: string
  pdsCustomerCountry?: string
  pdsCustomerZipCode?: string
}

// UN138
export interface ChangeParametersActionUn138 {
  eic: string
  processType: ProcessType.UN138
  reservedCapacityType: EicReservedCapacityType
  reservedCapacity: number
}

// UN139
export interface ChangeParametersActionUn139 {
  eic: string
  processType: ProcessType.UN139_1 | ProcessType.UN139_2 | ProcessType.UN139_3
  tariffCategory: string
  pdsContractType?: AgreeTypeDescription
  rate?: string
}

// UN148
export interface ChangeParametersActionUn148 {
  eic: string
  processType: ProcessType.UN148
  reservedCapacity: number
}

// UN150
export interface ChangeParametersActionUn150 {
  eic: string
  processType: ProcessType.UN150
  smallBusiness: EicSmallBusinessStatus
}

// Combined Action Payload Type
export type ChangeParametersAction =
  | ChangeParametersActionUn137
  | ChangeParametersActionUn138
  | ChangeParametersActionUn139
  | ChangeParametersActionUn148
  | ChangeParametersActionUn150

// ----------------------- Message 431 (Move-In) -----------------------
export enum MoveInProcessType {
  DEFAULT = ProcessType.UN121,
  SHORT_TIME = ProcessType.UN124
}

// UN121 / UN124
export interface MoveInAction {
  eic: string
  processType: MoveInProcessType
}

// ----------------------- Message 411 (Move-Out) -----------------------
export enum MoveOutProcessType {
  DEFAULT = ProcessType.UN102,
  SHORT_TIME = ProcessType.UN104
}

// UN102 / UN104
export interface MoveOutAction {
  eic: string
  processType: MoveOutProcessType
}

// ----------------------- Message 441 (PDS Service) -----------------------
export enum PdsServiceProcessType {
  DISTRIBUTION_INTERRUPTION = ProcessType.A001, // Prerušenie distribúcie
  DISTRIBUTION_RESTORATION = ProcessType.A002, // Obnovenie distribúcie
  /* Does not work, but we also don't need it:
  HISTORICAL_DATA = ProcessType.A003, // Historické dáta
  */
  OFF_CYCLE_BILLING_READING = ProcessType.A004, // Faktúra mimo cyklu (Nahlásenie stavu)
  SPLIT_BILLING_READING = ProcessType.A005, // Rozdelená faktúra (Nahlásenie stavu)
  OFF_CYCLE_BILLING_ADJUSTMENT = ProcessType.A006, // Faktúra mimo cyklu (Dopočet stavu)
  SPLIT_BILLING_ADJUSTMENT = ProcessType.A007, // Rozdelená faktúra (Dopočet stavu)
  OFF_CYCLE_BILLING_PHYSICAL_READING = ProcessType.A008, // Faktúra mimo cyklu (Fyzický odpočet)
  SPLIT_BILLING_PHYSICAL_READING = ProcessType.A009, // Rozdelená faktúra (Fyzický odpočet)
  SEALING_REQUEST = ProcessType.A010, // Požiadavka na zaplombovanie
  MEASUREMENT_CONTROL = ProcessType.A011, // Already present in your code
  METER_EXCHANGE = ProcessType.A012, // Výmena merania
  UNSEALING_REQUEST = ProcessType.A013, // Požiadavka na odplombovanie
  DISTRIBUTION_INTERRUPTION_DUE_TO_CONTRACT_DISPUTE = ProcessType.A014, // Prerušenie distribúcie z dôvodu rozporu zo združenou zmluvou
  STATE_REPORTING_ON_CONSUMER_CHANGE = ProcessType.A015 // Nahlásenie stavu pri zmene odberateľa
}

// A001 ... A015
export interface PdsServiceAction {
  processType: PdsServiceProcessType
  dateOfValidityFrom?: string
  historicDataFrom?: string
  historicDataTo?: string
  seq?: ISeqEntry[]
}

// ----------------------- Message 491 (Transfer Request) -----------------------
export enum TransferRequestProcessType {
  REPORT_OF_STATUS = ProcessType.UN191,
  REQUEST_FOR_STATUS_RECALCULATION = ProcessType.UN192,
  REQUEST_FOR_PHYSICAL_READING = ProcessType.UN193
}

// UN191 / UN192 / UN193
export interface TransferRequestAction {
  processType: TransferRequestProcessType
  dateFrom: string
  cazId: string
  companyId: string
  seq?: ISeqEntry[]
}

// ----------------------- Message 401 (Complaint) -----------------------
export enum ComplaintErrorType {
  INCORRECT_PERIOD = 'C01',
  INCORRECT_INVOICE_PERIOD_SPLIT = 'C02',
  PRICE = 'C03',
  INCORRECT_BILLING_ITEM = 'C04',
  INCORRECT_TAX_RATE = 'C05',
  INVOICE_NOT_MEETING_TAX_DOCUMENT_REQUIREMENTS = 'C06',
  INCORRECT_DOCUMENT_SYNTAX = 'C07',
  INCORRECT_IDENTIFICATION_DATA = 'C08',
  INCORRECT_CONSUMPTION = 'C09',
  READING_ERROR = 'C10',
  ESTIMATED_READING = 'C11',
  CURVE_REGISTER_MISMATCH = 'C12',
  INCORRECT_SPLIT_VT_NT = 'C13'
}

export interface ComplaintAction {
  errorType: ComplaintErrorType
  freeText: string
  originalDocumentId: string
}

// ----------------------- Producer EIC actions -------------------------
export enum ProducerEicActionTypes {
  CHANGE_OF_SUPPLIER = MessageCodeEnum.PRODUCER_CHANGE_OF_SUPPLIER,
  MOVE_IN = MessageCodeEnum.PRODUCER_MOVE_IN,
  MOVE_OUT = MessageCodeEnum.PRODUCER_MOVE_OUT
}

export interface ProducerEicAction {
  eic: string
  cazId: string
  dateFrom?: string
  dateTo?: string
}

// ----------------------- Combined payload type -----------------------
export type IActionPayload =
  | ChangeOfSupplierAction
  | ChangeParametersAction
  | MoveInAction
  | MoveOutAction
  | PdsServiceAction
  | TransferRequestAction
  | ComplaintAction
  | ProducerEicAction
