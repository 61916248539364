export const getSamePropertyValue = (
  arrayOfObjects: any[] | undefined,
  property: string,
  formatter?: any
): string | number | boolean | null | undefined => {
  const withFormatter = formatter && typeof formatter === 'function'
  if (
    arrayOfObjects?.every((item, index, arr) => {
      return withFormatter
        ? formatter(item[property]) === formatter(arr[0][property])
        : item[property] === arr[0][property]
    })
  ) {
    const result = arrayOfObjects?.[0]?.[property]
    return withFormatter ? formatter(result) : result
  }
  return '***'
}
